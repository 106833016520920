<template>
  <form @submit.prevent="$emit('submit')" class="space-y-6">
    <!-- Personal Information -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Prénom
        </label>
        <input
          v-model="modelValue.firstName"
          type="text"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Nom
        </label>
        <input
          v-model="modelValue.lastName"
          type="text"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
    </div>

    <div class="group">
      <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
        Email
      </label>
      <input
        v-model="modelValue.email"
        type="email"
        required
        class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
        :disabled="loading"
      >
    </div>

    <div v-if="showPassword" class="group">
      <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
        Mot de passe
      </label>
      <input
        v-model="modelValue.password"
        type="password"
        required
        class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
        :disabled="loading"
      >
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Date de naissance
        </label>
        <input
          v-model="modelValue.birthDate"
          type="date"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Mobile
        </label>
        <input
          v-model="modelValue.mobile"
          type="tel"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
    </div>

    <div class="group">
      <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
        Adresse
      </label>
      <input
        v-model="modelValue.address"
        type="text"
        required
        class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
        :disabled="loading"
      >
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Code postal
        </label>
        <input
          v-model="modelValue.postalCode"
          type="text"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
      <div class="group">
        <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
          Ville
        </label>
        <input
          v-model="modelValue.city"
          type="text"
          required
          class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
          :disabled="loading"
        >
      </div>
    </div>

    <!-- Interests -->
    <InterestsManager
      v-model="modelValue.interests"
      :suggested-interests="suggestedInterests"
    />

    <!-- Error Message -->
    <div v-if="error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
      <p class="text-sm text-white">{{ error }}</p>
    </div>

    <!-- Submit Button -->
    <div>
      <button
        type="submit"
        class="w-full flex justify-center py-3 px-4 rounded-lg text-sm font-semibold text-purple-700 bg-yellow-300 
               hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 
               transition-all disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="loading"
      >
        <svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-700" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <slot name="submit-text">{{ loading ? 'Chargement...' : 'Enregistrer' }}</slot>
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import InterestsManager from './InterestsManager.vue';

const props = defineProps<{
  modelValue: {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    birthDate: string;
    mobile: string;
    address: string;
    postalCode: string;
    city: string;
    interests: string[];
  };
  loading?: boolean;
  error?: string | null;
  showPassword?: boolean;
}>();

const suggestedInterests = [
  'Musique',
  'Cinéma',
  'Sport',
  'Lecture',
  'Cuisine',
  'Voyages',
  'Art',
  'Jeux vidéo',
  'Technologie',
  'Nature'
];

defineEmits<{
  (e: 'submit'): void;
  (e: 'update:modelValue', value: typeof props.modelValue): void;
}>();
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>