```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-12 px-4">
    <div class="max-w-3xl mx-auto">
      <!-- Header -->
      <div class="text-center mb-12">
        <div class="relative w-24 h-24 mx-auto mb-6">
          <GiftIcon />
        </div>
        <h1 class="text-3xl font-bold text-white mb-2">Suggérer un cadeau</h1>
        <p class="text-white/80">Partagez vos idées de cadeaux pour cet événement</p>
      </div>

      <!-- Form -->
      <form @submit.prevent="submitGiftSuggestion" class="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20">
        <div class="space-y-6">
          <!-- Gift Name -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Nom du cadeau</label>
            <input 
              v-model="form.name"
              type="text"
              required
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              placeholder="Ex: Nintendo Switch"
            >
          </div>

          <!-- Price -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Prix (€)</label>
            <input 
              v-model="form.price"
              type="number"
              required
              min="0"
              step="0.01"
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              placeholder="Ex: 299.99"
            >
          </div>

          <!-- Description -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Description</label>
            <textarea 
              v-model="form.description"
              rows="3"
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              placeholder="Décrivez le cadeau..."
            ></textarea>
          </div>

          <!-- Image Upload -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Photo du cadeau</label>
            <FileUpload @file-selected="handleFileSelected" />
          </div>

          <!-- Error Message -->
          <div v-if="error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
            <p class="text-sm text-white">{{ error }}</p>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-end space-x-4 pt-6">
            <button
              type="button"
              @click="$router.back()"
              class="px-6 py-3 bg-white/20 text-white rounded-lg font-semibold hover:bg-white/30 transition-all duration-200"
              :disabled="loading"
            >
              Annuler
            </button>
            <button
              type="submit"
              class="px-6 py-3 bg-yellow-300 text-purple-700 rounded-lg font-semibold hover:bg-yellow-200 
                     transform hover:scale-105 transition-all duration-200 shadow-lg disabled:opacity-50"
              :disabled="loading"
            >
              {{ loading ? 'Enregistrement...' : 'Suggérer ce cadeau' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useEventStore } from '../stores/events';
import { ImageProcessor } from '../utils/imageProcessor';
import GiftIcon from '../components/icons/GiftIcon.vue';
import FileUpload from '../components/FileUpload.vue';

const route = useRoute();
const router = useRouter();
const eventStore = useEventStore();

const form = ref({
  name: '',
  price: '',
  description: ''
});

const loading = ref(false);
const error = ref<string | null>(null);
const selectedFile = ref<File | null>(null);

const handleFileSelected = (file: File) => {
  selectedFile.value = file;
};

const submitGiftSuggestion = async () => {
  if (loading.value) return;
  
  loading.value = true;
  error.value = null;
  
  try {
    const eventId = route.params.id as string;
    let photoUrl = '';

    // Upload photo if selected
    if (selectedFile.value) {
      const fileName = `${form.value.name.toLowerCase().replace(/\s+/g, '-')}.jpg`;
      photoUrl = await ImageProcessor.uploadGiftImage(eventId, selectedFile.value, fileName);
    }

    const suggestion = {
      name: form.value.name,
      price: Number(form.value.price),
      description: form.value.description,
      photoUrl,
      suggestedBy: eventStore.currentUser?.id || ''
    };

    await eventStore.addGiftSuggestion(eventId, suggestion);
    router.push(`/events/${eventId}`);
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de la suggestion du cadeau';
    console.error('Error submitting gift suggestion:', err);
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}
</style>
```