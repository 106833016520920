<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-8 px-4">
    <div class="max-w-7xl mx-auto">
      <!-- Loading State -->
      <div v-if="friendStore.loading" class="flex justify-center items-center py-12">
        <div class="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div>
      </div>

      <template v-else>
        <!-- Header -->
        <div class="text-center mb-12">
          <div class="relative w-24 h-24 mx-auto mb-6">
            <div class="absolute inset-0 animate-party">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
                   class="w-full h-full text-yellow-300">
                <path d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" />
              </svg>
            </div>
          </div>
          <h1 class="text-4xl font-bold text-white mb-4">Vos amis</h1>
          <p class="text-white/80 text-lg mb-8">Partagez des moments inoubliables ensemble</p>
        </div>

        <!-- Error Message -->
        <div v-if="friendStore.error" 
             class="mb-8 rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
          <p class="text-sm text-white">{{ friendStore.error }}</p>
        </div>

        <!-- Action Buttons -->
        <div class="flex justify-center mb-12 space-x-4">
          <button @click="showAddFriendModal = true" 
                  class="group relative overflow-hidden px-8 py-4 rounded-full bg-yellow-300 text-purple-700 font-semibold 
                         hover:bg-yellow-200 transform hover:scale-105 transition-all duration-200 shadow-lg">
            <span class="relative z-10 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
              </svg>
              Ajouter manuellement
            </span>
          </button>

          <button @click="showSearchModal = true" 
                  class="group relative overflow-hidden px-8 py-4 rounded-full bg-white/20 text-white font-semibold 
                         hover:bg-white/30 transform hover:scale-105 transition-all duration-200 shadow-lg">
            <span class="relative z-10 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
              </svg>
              Rechercher des amis
            </span>
          </button>
        </div>

        <!-- Friends List -->
        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          <TransitionGroup 
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-for="friend in friendStore.friends" :key="friend.id"
                 class="group bg-white/10 backdrop-blur-lg rounded-2xl shadow-xl hover:shadow-2xl transform hover:-translate-y-1 
                        transition-all duration-200 overflow-hidden border border-white/20">
              <div class="relative p-6">
                <!-- Avatar -->
                <div class="w-16 h-16 mx-auto mb-4 relative">
                  <div class="absolute inset-0 animate-pulse-slow">
                    <div class="w-full h-full rounded-full bg-gradient-to-br from-yellow-300 to-yellow-400 
                               flex items-center justify-center text-2xl font-bold text-purple-700">
                      {{ friend.firstName.charAt(0).toUpperCase() }}
                    </div>
                  </div>
                </div>

                <div class="text-center mb-4">
                  <h3 class="text-xl font-semibold text-white mb-1">
                    {{ formatName.fullName(friend.firstName, friend.lastName) }}
                  </h3>
                  <p v-if="friend.email" class="text-white/70">{{ friend.email }}</p>
                  <p v-if="friend.mobile" class="text-white/70">{{ friend.mobile }}</p>
                  <p v-if="friend.birthDate" class="text-white/70">
                    Né(e) le {{ formatDate(friend.birthDate) }}
                  </p>
                </div>

                <!-- Action Icons -->
                <div class="flex justify-center space-x-4">
                  <a v-if="friend.mobile" 
                     :href="`tel:${friend.mobile}`"
                     class="p-2 rounded-lg text-yellow-300 hover:bg-white/10 transition-colors duration-200 
                            flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
                      <path fill-rule="evenodd" d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z" clip-rule="evenodd" />
                    </svg>
                  </a>

                  <a v-if="friend.email" 
                     :href="`mailto:${friend.email}`"
                     class="p-2 rounded-lg text-yellow-300 hover:bg-white/10 transition-colors duration-200 
                            flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                    </svg>
                  </a>

                  <button @click="editFriend(friend)"
                          class="p-2 rounded-lg text-yellow-300 hover:bg-white/10 transition-colors duration-200 
                                 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                    </svg>
                  </button>

                  <button @click="confirmDeleteFriend(friend)"
                          class="p-2 rounded-lg text-red-300 hover:bg-white/10 transition-colors duration-200 
                                 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>

        <!-- Empty State -->
        <div v-if="friendStore.friends.length === 0" 
             class="text-center py-12 bg-white/10 backdrop-blur-lg rounded-2xl border border-white/20">
          <div class="w-24 h-24 mx-auto mb-6 animate-party">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
                 class="w-full h-full text-yellow-300">
              <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
            </svg>
          </div>
          <h3 class="text-xl font-medium text-white mb-2">Aucun ami pour le moment</h3>
          <p class="text-white/80">Commencez par ajouter vos premiers amis !</p>
        </div>

        <!-- Modals -->
        <UserSearchModal
          :is-open="showSearchModal"
          @close="showSearchModal = false"
        />

        <AddFriendModal
          :is-open="showAddFriendModal"
          @close="showAddFriendModal = false"
        />

        <EditFriendModal
          v-if="selectedFriend"
          :is-open="showEditModal"
          :friend="selectedFriend"
          @close="closeEditModal"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useFriendStore } from '../stores/friends';
import { formatName, formatDate } from '../utils/formatters';
import UserSearchModal from '../components/UserSearchModal.vue';
import AddFriendModal from '../components/AddFriendModal.vue';
import EditFriendModal from '../components/EditFriendModal.vue';
import type { Friend } from '../types';

const friendStore = useFriendStore();
const showSearchModal = ref(false);
const showAddFriendModal = ref(false);
const showEditModal = ref(false);
const selectedFriend = ref<Friend | null>(null);

onMounted(async () => {
  await friendStore.fetchFriends();
});

const editFriend = (friend: Friend) => {
  selectedFriend.value = friend;
  showEditModal.value = true;
};

const closeEditModal = () => {
  showEditModal.value = false;
  selectedFriend.value = null;
};

const confirmDeleteFriend = async (friend: Friend) => {
  if (confirm(`Êtes-vous sûr de vouloir supprimer ${formatName.fullName(friend.firstName, friend.lastName)} de vos amis ?`)) {
    try {
      await friendStore.deleteFriend(friend.id);
    } catch (error) {
      console.error('Error deleting friend:', error);
    }
  }
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

.animate-pulse-slow {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes party {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(10deg) scale(1.1);
  }
  50% {
    transform: rotate(0deg) scale(1);
  }
  75% {
    transform: rotate(-10deg) scale(1.1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: .8;
    transform: scale(0.95);
  }
}
</style>