```vue
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                Conditions Générales d'Utilisation
              </DialogTitle>

              <div class="max-h-96 overflow-y-auto mb-6 space-y-4 text-sm text-gray-600">
                <p>Dernière mise à jour : {{ new Date().toLocaleDateString() }}</p>

                <h4 class="font-medium text-gray-900">1. Acceptation des conditions</h4>
                <p>
                  En utilisant Fêtup, vous acceptez d'être lié par les présentes Conditions Générales d'Utilisation.
                  Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'application.
                </p>

                <h4 class="font-medium text-gray-900">2. Description du service</h4>
                <p>
                  Fêtup est une application permettant d'organiser des événements et de gérer des cadeaux communs.
                  Nous nous réservons le droit de modifier ou d'interrompre le service à tout moment.
                </p>

                <h4 class="font-medium text-gray-900">3. Confidentialité</h4>
                <p>
                  Nous collectons et traitons vos données personnelles conformément à notre politique de confidentialité.
                  En utilisant Fêtup, vous consentez à cette collecte et à ce traitement.
                </p>

                <h4 class="font-medium text-gray-900">4. Responsabilités</h4>
                <p>
                  Vous êtes responsable de toutes les activités qui se produisent sous votre compte.
                  Vous vous engagez à ne pas utiliser le service à des fins illégales.
                </p>

                <h4 class="font-medium text-gray-900">5. Propriété intellectuelle</h4>
                <p>
                  Tout le contenu présent sur Fêtup est protégé par des droits de propriété intellectuelle.
                  Vous vous engagez à respecter ces droits.
                </p>

                <h4 class="font-medium text-gray-900">6. Modifications</h4>
                <p>
                  Nous nous réservons le droit de modifier ces conditions à tout moment.
                  Les modifications entrent en vigueur dès leur publication.
                </p>
              </div>

              <div class="mt-6 flex flex-col gap-4">
                <div class="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="accept-terms"
                    v-model="accepted"
                    class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                  >
                  <label for="accept-terms" class="text-sm text-gray-700">
                    J'ai lu et j'accepte les conditions générales d'utilisation
                  </label>
                </div>

                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    @click="confirmAcceptance"
                    :disabled="!accepted"
                    class="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Accepter et continuer
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'accept'): void;
}>();

const accepted = ref(false);

const closeModal = () => {
  accepted.value = false;
  emit('close');
};

const confirmAcceptance = () => {
  if (accepted.value) {
    emit('accept');
    closeModal();
  }
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(107, 114, 128, 0.3) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(107, 114, 128, 0.3);
  border-radius: 3px;
}
</style>
```