import { MAX_IMAGE_SIZE, MAX_DIMENSION, SUPPORTED_IMAGE_TYPES, ERROR_MESSAGES } from '../config/constants';
import imageCompression from 'browser-image-compression';
import { storage } from '../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export class ImageProcessor {
  static async validateImage(file: File | Blob): Promise<void> {
    if (file instanceof File && !SUPPORTED_IMAGE_TYPES.includes(file.type)) {
      throw new Error(ERROR_MESSAGES.UPLOAD.INVALID_TYPE);
    }

    if (file.size > MAX_IMAGE_SIZE) {
      throw new Error(ERROR_MESSAGES.UPLOAD.SIZE_LIMIT);
    }
  }

  static async compressImage(file: File | Blob): Promise<Blob> {
    await this.validateImage(file);

    try {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: MAX_DIMENSION,
        useWebWorker: true,
        fileType: 'image/jpeg',
        initialQuality: 0.8,
        alwaysKeepResolution: true
      };

      const compressedFile = await imageCompression(file instanceof File ? file : new File([file], 'image.jpg', { type: 'image/jpeg' }), options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw new Error(ERROR_MESSAGES.UPLOAD.COMPRESSION);
    }
  }

  static async uploadGiftImage(
    eventId: string,
    file: File | Blob,
    fileName: string,
    onProgress?: (progress: number) => void
  ): Promise<string> {
    if (!eventId) {
      throw new Error(ERROR_MESSAGES.UPLOAD.MISSING_EVENT);
    }

    try {
      // Show initial progress
      if (onProgress) onProgress(0);

      // Compress image
      const compressedImage = await this.compressImage(file);
      if (onProgress) onProgress(30);

      // Prepare upload
      const timestamp = Date.now();
      const safeFileName = `${timestamp}-${fileName.replace(/[^a-zA-Z0-9.]/g, '_')}`.toLowerCase();
      const path = `gift-suggestions/${eventId}/${safeFileName}`;
      const storageRef = ref(storage, path);

      // Upload with retry mechanism
      let retryCount = 0;
      const maxRetries = 3;

      while (retryCount < maxRetries) {
        try {
          const uploadTask = uploadBytesResumable(storageRef, compressedImage, {
            contentType: 'image/jpeg',
            cacheControl: 'public,max-age=31536000',
            customMetadata: {
              eventId,
              timestamp: timestamp.toString(),
              attempt: (retryCount + 1).toString()
            }
          });

          const downloadURL = await new Promise<string>((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                if (onProgress) {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 70;
                  onProgress(30 + Math.round(progress)); // 30-100%
                }
              },
              (error) => {
                console.warn(`Upload attempt ${retryCount + 1} failed:`, error);
                reject(error);
              },
              async () => {
                try {
                  const url = await getDownloadURL(uploadTask.snapshot.ref);
                  if (onProgress) onProgress(100);
                  resolve(url);
                } catch (error) {
                  reject(error);
                }
              }
            );
          });

          return downloadURL;
        } catch (error: any) {
          retryCount++;
          
          if (error.code === 'storage/unauthorized') {
            throw new Error(ERROR_MESSAGES.UPLOAD.UNAUTHORIZED);
          }

          if (retryCount === maxRetries) {
            throw new Error(ERROR_MESSAGES.UPLOAD.RETRY_LIMIT);
          }

          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 1000));
        }
      }

      throw new Error(ERROR_MESSAGES.UPLOAD.GENERIC);
    } catch (error: any) {
      console.error('Error uploading image:', error);
      throw new Error(error.message || ERROR_MESSAGES.UPLOAD.GENERIC);
    }
  }

  static createObjectURL(blob: Blob): string {
    return URL.createObjectURL(blob);
  }

  static revokeObjectURL(url: string): void {
    try {
      URL.revokeObjectURL(url);
    } catch (error) {
      console.warn('Error revoking object URL:', error);
    }
  }
}