<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400">
    <Navbar v-if="authStore.isAuthenticated" />
    <MobileBottomNav v-if="authStore.isAuthenticated" />
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from './stores/auth';
import Navbar from './components/Navbar.vue';
import MobileBottomNav from './components/MobileBottomNav.vue';

const authStore = useAuthStore();
</script>