<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                Ajouter un ami
              </DialogTitle>

              <form @submit.prevent="saveFriend" class="mt-4 space-y-4">
                <div>
                  <label class="block text-sm font-medium text-gray-700">Prénom</label>
                  <input 
                    v-model="form.firstName"
                    type="text"
                    required
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-700">Nom</label>
                  <input 
                    v-model="form.lastName"
                    type="text"
                    required
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-700">Date de naissance</label>
                  <input 
                    v-model="form.birthDate"
                    type="date"
                    required
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    Mobile <span class="text-gray-400">(optionnel)</span>
                  </label>
                  <input 
                    v-model="form.mobile"
                    type="tel"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                </div>

                <div>
                  <label class="block text-sm font-medium text-gray-700">
                    Email <span class="text-gray-400">(optionnel)</span>
                  </label>
                  <input 
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                </div>

                <div class="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md border border-gray-300"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    class="px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-md"
                    :disabled="loading"
                  >
                    {{ loading ? 'Enregistrement...' : 'Ajouter' }}
                  </button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useFriendStore } from '../stores/friends';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const friendStore = useFriendStore();
const loading = ref(false);

const form = ref({
  firstName: '',
  lastName: '',
  birthDate: '',
  mobile: '',
  email: ''
});

const saveFriend = async () => {
  loading.value = true;
  try {
    await friendStore.addFriend(form.value);
    closeModal();
  } catch (error) {
    console.error('Error adding friend:', error);
  } finally {
    loading.value = false;
  }
};

const closeModal = () => {
  form.value = {
    firstName: '',
    lastName: '',
    birthDate: '',
    mobile: '',
    email: ''
  };
  emit('close');
};
</script>