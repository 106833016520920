```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-12 px-4">
    <div class="max-w-2xl mx-auto">
      <!-- Header -->
      <div class="text-center mb-8">
        <h1 class="text-3xl font-bold text-white">Nouvel événement</h1>
        <p class="text-white/80 mt-2">Créez un nouvel événement</p>
      </div>

      <!-- Form -->
      <form @submit.prevent="createEvent" class="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20">
        <div class="space-y-6">
          <!-- Event Type -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Type d'événement</label>
            <select
              v-model="form.type"
              required
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50 [&>option[value='']]:text-white"
            >
              <option value="" disabled class="text-gray-900 bg-white">Sélectionnez un type</option>
              <option value="birthday" class="text-gray-900 bg-white">Anniversaire</option>
              <option value="party" class="text-gray-900 bg-white">Soirée</option>
              <option value="christmas" class="text-gray-900 bg-white">Noël</option>
              <option value="retirement" class="text-gray-900 bg-white">Retraite</option>
              <option value="newYear" class="text-gray-900 bg-white">Nouvel An</option>
              <option value="dinner" class="text-gray-900 bg-white">Repas</option>
              <option value="other" class="text-gray-900 bg-white">Autre</option>
            </select>
          </div>

          <!-- Friend Search -->
          <FriendSearch v-model="selectedFriend" />

          <!-- Event Date -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Date de l'événement</label>
            <input 
              v-model="form.date"
              type="date"
              required
              :min="minDate"
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <!-- Time (optional) -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">
              Invitation à 
              <span class="text-white/60">(optionnel)</span>
            </label>
            <input 
              v-model="form.time"
              type="time"
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <!-- Error Message -->
          <div v-if="error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
            <p class="text-sm text-white">{{ error }}</p>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end pt-4">
            <button
              type="submit"
              class="px-6 py-3 bg-yellow-300 text-purple-700 rounded-lg font-semibold hover:bg-yellow-200 
                     transform hover:scale-105 transition-all duration-200 shadow-lg disabled:opacity-50"
              :disabled="loading || !selectedFriend"
            >
              {{ loading ? 'Création...' : 'Créer l\'événement' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useEventStore } from '../stores/events';
import { formatName } from '../utils/formatters';
import FriendSearch from '../components/FriendSearch.vue';
import type { Event, Friend } from '../types';

const router = useRouter();
const eventStore = useEventStore();
const loading = ref(false);
const error = ref<string | null>(null);
const selectedFriend = ref<Friend | null>(null);

const form = ref({
  type: '',
  date: '',
  time: '',
});

// Get today's date in YYYY-MM-DD format for the min attribute
const minDate = computed(() => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
});

const validateDateTime = (date: string, time: string): Date => {
  const dateTimeStr = time ? `${date}T${time}:00` : `${date}T00:00:00`;
  const dateTime = new Date(dateTimeStr);
  
  if (isNaN(dateTime.getTime())) {
    throw new Error('Date ou heure invalide');
  }

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  
  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);
  
  if (selectedDate < now) {
    throw new Error('La date ne peut pas être antérieure à aujourd\'hui');
  }
  
  return dateTime;
};

const createEvent = async () => {
  if (loading.value || !selectedFriend.value) return;
  
  loading.value = true;
  error.value = null;

  try {
    const eventDate = validateDateTime(form.value.date, form.value.time);

    const eventData: Omit<Event, 'id' | 'createdBy' | 'createdAt'> = {
      type: form.value.type as Event['type'],
      name: formatName.fullName(selectedFriend.value.firstName, selectedFriend.value.lastName),
      date: eventDate.toISOString(),
      participants: []
    };

    await eventStore.createEvent(eventData);
    router.push('/events');
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de la création de l\'événement';
    console.error('Error creating event:', err);
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

select option {
  background-color: white;
  color: #1a1a1a;
}

select option[value=""] {
  color: white;
}
</style>
```