```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-8 px-4">
    <div class="max-w-7xl mx-auto">
      <!-- Loading State -->
      <div v-if="eventStore.loading" class="flex justify-center items-center py-12">
        <div class="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent"></div>
      </div>

      <template v-else>
        <!-- Header -->
        <div class="text-center mb-12">
          <div class="relative w-32 h-32 mx-auto mb-6">
            <SparklesIcon />
          </div>
          <h1 class="text-4xl font-bold text-white mb-4">Vos événements</h1>
          <p class="text-white/80 text-lg mb-8">Organisez et gérez vos événements</p>
        </div>

        <!-- Error Message -->
        <div v-if="eventStore.error" 
             class="mb-8 rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
          <p class="text-sm text-white">{{ eventStore.error }}</p>
        </div>

        <!-- New Event Button -->
        <div class="flex justify-center mb-8">
          <router-link 
            to="/events/new"
            class="px-6 py-3 bg-yellow-300 text-purple-700 rounded-lg font-semibold 
                   hover:bg-yellow-200 transform hover:scale-105 transition-all duration-200 shadow-lg"
          >
            Créer un événement
          </router-link>
        </div>

        <!-- Events List -->
        <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          <TransitionGroup 
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-for="event in eventStore.events" :key="event.id"
                 class="relative bg-white/10 backdrop-blur-lg rounded-2xl p-6 shadow-xl hover:shadow-2xl transform hover:-translate-y-1 
                        transition-all duration-200 overflow-hidden border border-white/20">
              <!-- Gift Link -->
              <GiftLink :event-id="event.id" />
              
              <!-- Event Type -->
              <div :class="getEventTypeBadgeClass(event.type)" 
                   class="inline-block px-3 py-1 rounded-full text-sm font-medium mb-3">
                {{ getEventTypeLabel(event.type) }}
              </div>

              <!-- Main Info -->
              <h3 class="text-xl font-bold text-white mb-2">{{ event.name }}</h3>
              
              <div class="space-y-3 text-white/80 mb-4">
                <div class="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                  </svg>
                  {{ formatDate(event.date) }}
                </div>

                <div v-if="event.gift" class="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
                    <path d="M9 11H3v5a2 2 0 002 2h4v-7zm2 7h4a2 2 0 002-2v-5h-6v7z" />
                  </svg>
                  Budget cadeau: {{ event.gift.budget }}€
                </div>
              </div>

              <!-- Actions -->
              <div class="space-y-2">
                <!-- Gift Details -->
                <div v-if="event.gift" class="flex-1">
                  <button
                    @click="handleGiftDetails(event)"
                    class="w-full flex items-center justify-center px-4 py-2 rounded-lg text-yellow-300 
                           hover:bg-white/10 transition-colors duration-200 space-x-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                    </svg>
                    <span>Voir le cadeau</span>
                  </button>
                </div>

                <!-- Edit & Delete -->
                <div class="flex space-x-2">
                  <router-link 
                    :to="`/events/${event.id}/edit`"
                    class="p-2 rounded-lg text-yellow-300 hover:bg-white/10 transition-colors duration-200"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </router-link>
                  
                  <button 
                    @click="confirmDeleteEvent(event)"
                    class="p-2 rounded-lg text-red-300 hover:bg-white/10 transition-colors duration-200"
                    :disabled="eventStore.deletingEventIds.has(event.id)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>

        <!-- Empty State -->
        <div v-if="eventStore.events.length === 0" 
             class="text-center py-12 bg-white/10 backdrop-blur-lg rounded-2xl border border-white/20">
          <div class="w-32 h-32 mx-auto mb-6">
            <SparklesIcon />
          </div>
          <h3 class="text-xl font-medium text-white mb-2">Aucun événement</h3>
          <p class="text-white/80">Commencez par créer votre premier événement !</p>
        </div>

        <!-- Modals -->
        <GiftDetailsModal
          :is-open="modals.gift"
          :event="selectedEvent"
          @close="closeModals"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { TransitionGroup } from 'vue';
import { useEventStore } from '../stores/events';
import { useAuthStore } from '../stores/auth';
import { formatDate } from '../utils/formatters';
import GiftDetailsModal from '../components/GiftDetailsModal.vue';
import SparklesIcon from '../components/icons/SparklesIcon.vue';
import GiftLink from '../components/GiftLink.vue';
import type { Event } from '../types';

const eventStore = useEventStore();
const authStore = useAuthStore();

const selectedEvent = ref<Event | null>(null);
const modals = reactive({
  gift: false
});

onMounted(async () => {
  await eventStore.fetchEvents();
});

const getEventTypeBadgeClass = (type: Event['type']) => {
  const classes = {
    birthday: 'bg-pink-500/30 text-pink-200 border border-pink-500',
    party: 'bg-purple-500/30 text-purple-200 border border-purple-500',
    christmas: 'bg-red-500/30 text-red-200 border border-red-500',
    retirement: 'bg-blue-500/30 text-blue-200 border border-blue-500',
    newYear: 'bg-green-500/30 text-green-200 border border-green-500',
    dinner: 'bg-orange-500/30 text-orange-200 border border-orange-500',
    other: 'bg-gray-500/30 text-gray-200 border border-gray-500'
  };
  return classes[type] || classes.other;
};

const getEventTypeLabel = (type: Event['type']) => {
  const labels = {
    birthday: 'Anniversaire',
    party: 'Soirée',
    christmas: 'Noël',
    retirement: 'Retraite',
    newYear: 'Nouvel An',
    dinner: 'Repas',
    other: 'Autre'
  };
  return labels[type] || 'Autre';
};

const confirmDeleteEvent = async (event: Event) => {
  if (!event?.id) return;
  
  const confirmMessage = `Êtes-vous sûr de vouloir supprimer l'événement "${event.name}" ?`;
  
  if (window.confirm(confirmMessage)) {
    try {
      await eventStore.deleteEvent(event.id);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  }
};

const handleGiftDetails = (event: Event) => {
  selectedEvent.value = event;
  modals.gift = true;
};

const closeModals = () => {
  modals.gift = false;
  selectedEvent.value = null;
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

@keyframes party {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(10deg) scale(1.1); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
}
</style>
```