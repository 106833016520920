```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white/10 backdrop-blur-lg p-8 rounded-2xl shadow-xl">
      <div class="text-center">
        <!-- Logo animé -->
        <div class="mb-8">
          <div class="relative w-32 h-32 mx-auto">
            <!-- Gift Icon -->
            <div class="absolute inset-0">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300 animate-float">
                <path fill="none" stroke="currentColor" stroke-width="1.5" d="M20 12v7a2 2 0 01-2 2H6a2 2 0 01-2-2v-7"/>
                <path fill="none" stroke="currentColor" stroke-width="1.5" d="M22 8H2v4h20V8zM12 22V8m0 0H7.5a2.5 2.5 0 110-5C9 3 12 3 12 8zm0 0h4.5a2.5 2.5 0 100-5C15 3 12 3 12 8z"/>
              </svg>
            </div>

            <!-- Sparkles -->
            <div class="absolute inset-0">
              <div class="absolute -top-4 -left-4 animate-sparkle-1">
                <div class="w-3 h-3 bg-white rounded-full"></div>
              </div>
              <div class="absolute -top-6 left-1/2 animate-sparkle-2">
                <div class="w-3 h-3 bg-white rounded-full"></div>
              </div>
              <div class="absolute -top-4 -right-4 animate-sparkle-3">
                <div class="w-3 h-3 bg-white rounded-full"></div>
              </div>
              <div class="absolute top-1/2 -right-6 animate-sparkle-4">
                <div class="w-3 h-3 bg-white rounded-full"></div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-6xl font-extrabold text-white mb-4">
          Fêt'Ami
        </h2>
        <p class="text-white/80 text-lg mb-4">
          Organisez vos événements festifs en toute simplicité !
        </p>
        <p class="text-white/80 text-sm">
          Ou
          <router-link to="/register" class="font-medium text-yellow-300 hover:text-yellow-200 transition-colors">
            rejoignez la fête
          </router-link>
        </p>
      </div>

      <form class="mt-8 space-y-6" @submit.prevent="handleLogin">
        <div class="space-y-4">
          <div class="group">
            <label for="email" class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
              Adresse email
            </label>
            <input
              id="email"
              v-model="email"
              type="email"
              required
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              :disabled="authStore.loading"
              placeholder="vous@example.com"
            >
          </div>

          <div class="group">
            <label for="password" class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
              Mot de passe
            </label>
            <input
              id="password"
              v-model="password"
              type="password"
              required
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              :disabled="authStore.loading"
              placeholder="••••••••"
            >
          </div>
        </div>

        <div v-if="authStore.error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
          <p class="text-sm text-white">{{ authStore.error }}</p>
        </div>

        <div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-3 px-4 rounded-lg text-sm font-semibold text-purple-700 bg-yellow-300 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="authStore.loading"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg v-if="!authStore.loading" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-500 group-hover:text-purple-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="animate-spin h-5 w-5 text-purple-500" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
            {{ authStore.loading ? 'Connexion en cours...' : 'Se connecter' }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';

const router = useRouter();
const authStore = useAuthStore();

const email = ref('');
const password = ref('');

const handleLogin = async () => {
  try {
    await authStore.login(email.value, password.value);
    router.push('/events');
  } catch (error) {
    console.error('Login failed:', error);
  }
};
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-sparkle-1 {
  animation: sparkle 2s ease-in-out infinite;
}

.animate-sparkle-2 {
  animation: sparkle 2s ease-in-out infinite 0.5s;
}

.animate-sparkle-3 {
  animation: sparkle 2s ease-in-out infinite 1s;
}

.animate-sparkle-4 {
  animation: sparkle 2s ease-in-out infinite 1.5s;
}

@keyframes float {
  0%, 100% { 
    transform: translateY(0); 
  }
  50% { 
    transform: translateY(-10px); 
  }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
}
</style>
```