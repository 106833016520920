```vue
<template>
  <div class="relative">
    <!-- Photo Preview -->
    <div v-if="photos.length > 0" class="relative w-full h-64">
      <img 
        :src="photos[currentIndex]" 
        class="w-full h-full object-cover rounded-lg"
        alt="Photo preview"
      >
      
      <!-- Navigation Buttons -->
      <div class="absolute inset-y-0 left-0 flex items-center">
        <button
          v-if="photos.length > 1"
          @click="previousPhoto"
          class="p-2 bg-black/25 text-white rounded-r-lg hover:bg-black/40 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
      
      <div class="absolute inset-y-0 right-0 flex items-center">
        <button
          v-if="photos.length > 1"
          @click="nextPhoto"
          class="p-2 bg-black/25 text-white rounded-l-lg hover:bg-black/40 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>

      <!-- Photo Counter -->
      <div v-if="photos.length > 1" 
           class="absolute bottom-4 left-1/2 -translate-x-1/2 px-3 py-1 bg-black/50 rounded-full text-white text-sm">
        {{ currentIndex + 1 }} / {{ photos.length }}
      </div>
    </div>

    <!-- Empty State -->
    <div v-else class="w-full h-64 bg-white/10 rounded-lg flex items-center justify-center">
      <div class="text-center p-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto text-white/60 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <p class="text-white/80 text-sm">Aucune photo disponible</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  photos: string[];
}>();

const currentIndex = ref(0);

const nextPhoto = () => {
  if (currentIndex.value < props.photos.length - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0;
  }
};

const previousPhoto = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  } else {
    currentIndex.value = props.photos.length - 1;
  }
};
</script>
```