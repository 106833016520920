<template>
  <!-- Desktop Navigation -->
  <nav class="hidden md:block bg-white/10 backdrop-blur-lg border-b border-white/20">
    <div class="max-w-7xl mx-auto px-4">
      <div class="flex justify-between h-16">
        <!-- Logo and welcome message -->
        <div class="flex items-center space-x-4">
          <router-link to="/" class="flex items-center space-x-2">
            <div class="relative w-8 h-8">
              <!-- Gift Icon -->
              <div class="absolute inset-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300 animate-float">
                  <path fill="none" stroke="currentColor" stroke-width="1.5" d="M20 12v7a2 2 0 01-2 2H6a2 2 0 01-2-2v-7"/>
                  <path fill="none" stroke="currentColor" stroke-width="1.5" d="M22 8H2v4h20V8zM12 22V8m0 0H7.5a2.5 2.5 0 110-5C9 3 12 3 12 8zm0 0h4.5a2.5 2.5 0 100-5C15 3 12 3 12 8z"/>
                </svg>
              </div>

              <!-- Sparkles -->
              <div class="absolute inset-0">
                <div class="absolute -top-1 -left-1 animate-sparkle-1">
                  <div class="w-2 h-2 bg-white rounded-full"></div>
                </div>
                <div class="absolute -top-2 left-1/2 animate-sparkle-2">
                  <div class="w-2 h-2 bg-white rounded-full"></div>
                </div>
                <div class="absolute -top-1 -right-1 animate-sparkle-3">
                  <div class="w-2 h-2 bg-white rounded-full"></div>
                </div>
                <div class="absolute top-1/2 -right-2 animate-sparkle-4">
                  <div class="w-2 h-2 bg-white rounded-full"></div>
                </div>
              </div>
            </div>
            <span class="text-2xl font-bold text-white">Fêt'Ami</span>
          </router-link>
          
          <!-- Welcome message -->
          <div class="hidden md:flex items-center space-x-2 text-white/90">
            <span class="text-sm">Bienvenue,</span>
            <router-link to="/profile" class="font-medium text-yellow-300 hover:text-yellow-200 transition-colors">
              {{ formatName.firstName(authStore.currentUser?.firstName) }} !
            </router-link>
          </div>
        </div>

        <!-- Desktop Navigation Links -->
        <div class="hidden md:flex items-center space-x-1">
          <router-link 
            v-for="item in navigationItems"
            :key="item.path"
            :to="item.path" 
            class="group px-3 py-2 rounded-lg text-white/90 hover:text-yellow-300 hover:bg-white/10 transition-all duration-200"
            :class="{ 'bg-white/10 text-yellow-300': isActive(item.path) }"
          >
            <span class="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" v-html="item.icon" />
              <span>{{ item.name }}</span>
            </span>
          </router-link>

          <button 
            @click="logout" 
            class="group px-3 py-2 rounded-lg text-white/90 hover:text-red-300 hover:bg-white/10 transition-all duration-200"
          >
            <span class="flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              <span>Déconnexion</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { formatName } from '../utils/formatters';

const router = useRouter();
const authStore = useAuthStore();

const navigationItems = [
  {
    name: 'Événements',
    path: '/events',
    icon: '<path d="M5 3a2 2 0 012-2h6a2 2 0 012 2v11h-4v4H7v-4H3V3z" /><path d="M3 3h2v2H3V3z" />'
  },
  {
    name: 'Agenda',
    path: '/calendar',
    icon: '<path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />'
  },
  {
    name: 'Amis',
    path: '/friends',
    icon: '<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />'
  }
];

const isActive = (path: string): boolean => {
  if (path === '/events') {
    return router.currentRoute.value.path.startsWith('/events');
  }
  return router.currentRoute.value.path === path;
};

const logout = async () => {
  await authStore.logout();
  router.push('/');
};
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-sparkle-1 {
  animation: sparkle 2s ease-in-out infinite;
}

.animate-sparkle-2 {
  animation: sparkle 2s ease-in-out infinite 0.5s;
}

.animate-sparkle-3 {
  animation: sparkle 2s ease-in-out infinite 1s;
}

.animate-sparkle-4 {
  animation: sparkle 2s ease-in-out infinite 1.5s;
}

@keyframes float {
  0%, 100% { 
    transform: translateY(0); 
  }
  50% { 
    transform: translateY(-2px); 
  }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
}
</style>