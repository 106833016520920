import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import LoginPage from '../views/LoginPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
import EventList from '../views/EventList.vue';
import NewEvent from '../views/NewEvent.vue';
import EditEvent from '../views/EditEvent.vue';
import SuggestGift from '../views/SuggestGift.vue';
import FriendList from '../views/FriendList.vue';
import ProfilePage from '../views/ProfilePage.vue';
import CalendarPage from '../views/CalendarPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterPage
    },
    {
      path: '/events',
      name: 'events',
      component: EventList,
      meta: { requiresAuth: true }
    },
    {
      path: '/events/new',
      name: 'new-event',
      component: NewEvent,
      meta: { requiresAuth: true }
    },
    {
      path: '/events/:id/edit',
      name: 'edit-event',
      component: EditEvent,
      meta: { requiresAuth: true }
    },
    {
      path: '/events/:id/suggest-gift',
      name: 'suggest-gift',
      component: SuggestGift,
      meta: { requiresAuth: true }
    },
    {
      path: '/friends',
      name: 'friends',
      component: FriendList,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfilePage,
      meta: { requiresAuth: true }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: CalendarPage,
      meta: { requiresAuth: true }
    }
  ]
});

// Navigation guard
router.beforeEach(async (to) => {
  const authStore = useAuthStore();
  
  // Wait for auth initialization
  if (!authStore.isInitialized) {
    await authStore.init();
  }

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return { name: 'login' };
  }

  if (authStore.isAuthenticated && (to.name === 'login' || to.name === 'register')) {
    return { name: 'events' };
  }
});

export default router;