import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { auth } from './firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import './index.css';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

// Initialize auth store
import { useAuthStore } from './stores/auth';
const authStore = useAuthStore();

// Initialize the app after auth state is ready
onAuthStateChanged(auth, async (user) => {
  if (!authStore.isInitialized) {
    try {
      await authStore.init();
    } catch (error) {
      console.error('Error initializing auth:', error);
    }
    app.mount('#app');
  }
});