// Image processing constants
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB
export const MAX_DIMENSION = 1920; // Maximum width/height in pixels
export const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
export const IMAGE_QUALITY = {
  HIGH: 0.9,
  MEDIUM: 0.7,
  LOW: 0.5
};

// Error messages
export const ERROR_MESSAGES = {
  UPLOAD: {
    UNAUTHORIZED: 'Non autorisé à télécharger des photos',
    CANCELED: 'Téléchargement annulé',
    RETRY_LIMIT: 'Le téléchargement a échoué après plusieurs tentatives. Veuillez vérifier votre connexion et réessayer.',
    GENERIC: 'Une erreur est survenue lors du téléchargement. Veuillez réessayer.',
    COMPRESSION: 'Erreur lors de la compression de l\'image',
    INVALID_TYPE: 'Type de fichier non supporté (JPG, PNG ou GIF uniquement)',
    SIZE_LIMIT: 'La taille du fichier dépasse 5MB',
    MISSING_EVENT: 'ID de l\'événement manquant',
    NETWORK: 'Problème de connexion réseau. Veuillez vérifier votre connexion et réessayer.',
    CAMERA: 'Impossible d\'accéder à la caméra'
  }
} as const;