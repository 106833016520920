```vue
<template>
  <div class="relative">
    <!-- Preview -->
    <div v-if="preview" class="relative w-full h-64">
      <img 
        :src="preview" 
        class="w-full h-full object-cover rounded-lg"
        alt="Image preview"
      >
      <button
        @click="clearFile"
        class="absolute top-2 right-2 p-2 bg-white/20 backdrop-blur-sm rounded-full hover:bg-white/30 transition-colors"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <!-- Upload Zone -->
    <div v-else 
         class="w-full h-64 bg-white/10 rounded-lg border-2 border-dashed border-white/30 
                hover:border-yellow-300/50 transition-colors cursor-pointer"
         @click="triggerFileInput"
         @dragover.prevent="isDragging = true"
         @dragleave.prevent="isDragging = false"
         @drop.prevent="handleDrop"
         :class="{ 'border-yellow-300/50': isDragging }"
    >
      <div class="h-full flex flex-col items-center justify-center p-6">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white/60 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
        <p class="text-white/80 text-center mb-2">
          Glissez-déposez une image ici ou
          <span class="text-yellow-300">parcourez</span>
        </p>
        <p class="text-white/60 text-sm text-center">
          PNG, JPG ou GIF (max. 5MB)
        </p>
      </div>
    </div>

    <!-- Hidden File Input -->
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept="image/*"
      @change="handleFileSelect"
    >

    <!-- Error Message -->
    <div v-if="error" class="mt-2 text-sm text-red-300">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ImageProcessor } from '../utils/imageProcessor';

const emit = defineEmits<{
  (e: 'file-selected', file: File): void;
}>();

const fileInput = ref<HTMLInputElement | null>(null);
const preview = ref<string | null>(null);
const error = ref<string | null>(null);
const isDragging = ref(false);

const triggerFileInput = () => {
  fileInput.value?.click();
};

const validateFile = (file: File): boolean => {
  // Check file type
  if (!file.type.startsWith('image/')) {
    error.value = 'Le fichier doit être une image (PNG, JPG ou GIF)';
    return false;
  }

  // Check file size (5MB)
  if (file.size > 5 * 1024 * 1024) {
    error.value = 'L\'image ne doit pas dépasser 5MB';
    return false;
  }

  return true;
};

const handleFile = async (file: File) => {
  error.value = null;

  if (!validateFile(file)) {
    return;
  }

  try {
    // Create preview
    preview.value = URL.createObjectURL(file);
    
    // Emit the file
    emit('file-selected', file);
  } catch (err: any) {
    console.error('Error processing file:', err);
    error.value = 'Une erreur est survenue lors du traitement de l\'image';
    clearFile();
  }
};

const handleFileSelect = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const file = input.files?.[0];
  
  if (file) {
    handleFile(file);
  }
  
  // Reset input
  input.value = '';
};

const handleDrop = (event: DragEvent) => {
  isDragging.value = false;
  const file = event.dataTransfer?.files[0];
  
  if (file) {
    handleFile(file);
  }
};

const clearFile = () => {
  if (preview.value) {
    URL.revokeObjectURL(preview.value);
  }
  preview.value = null;
  error.value = null;
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};
</script>
```