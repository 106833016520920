<template>
  <!-- Mobile Navigation -->
  <nav class="md:hidden fixed top-0 left-0 right-0 z-50 bg-white/10 backdrop-blur-lg border-b border-white/20 pt-safe-top">
    <div class="flex items-center justify-between px-4 h-16">
      <!-- Logo -->
      <router-link to="/" class="flex items-center space-x-2">
        <div class="relative w-8 h-8">
          <div class="absolute inset-0 animate-party">
            <div class="w-full h-full rounded-full bg-yellow-300 relative overflow-hidden">
              <div class="absolute inset-0">
                <div class="absolute left-1/4 top-1/2 -translate-y-1/2 w-4 h-6 bg-white/50 blur-[1px]"></div>
                <div class="absolute right-1/4 top-1/2 -translate-y-1/2 w-4 h-6 bg-white/50 blur-[1px]"></div>
              </div>
            </div>
          </div>
        </div>
        <span class="text-xl font-bold text-white">Fêt'Ami</span>
      </router-link>

      <!-- Profile Link -->
      <router-link 
        to="/profile"
        class="flex items-center text-white/90 active:scale-95 transition-transform"
      >
        <span class="text-sm">Bienvenue, </span>
        <span class="font-medium text-yellow-300 ml-1 flex items-center">
          {{ formatName.firstName(authStore.currentUser?.firstName) }}
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
        </span>
      </router-link>
    </div>
  </nav>

  <!-- Navigation Menu -->
  <nav class="md:hidden fixed bottom-0 left-0 right-0 z-50 bg-white/10 backdrop-blur-lg border-t border-white/20 pb-safe-bottom">
    <div class="grid grid-cols-3 gap-1 px-2">
      <router-link 
        v-for="item in navigationItems"
        :key="item.path"
        :to="item.path"
        class="flex flex-col items-center justify-center py-3 px-2 rounded-lg text-white/70 hover:text-yellow-300 active:scale-95 transition-all"
        :class="{ 'text-yellow-300': isActive(item.path) }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mb-1" viewBox="0 0 20 20" fill="currentColor" v-html="item.icon" />
        <span class="text-xs">{{ item.name }}</span>
      </router-link>
    </div>
  </nav>

  <!-- Spacers -->
  <div class="h-16 md:h-0"></div>
  <div class="h-20 md:h-0"></div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { formatName } from '../utils/formatters';

const route = useRoute();
const authStore = useAuthStore();

const navigationItems = [
  {
    name: 'Événements',
    path: '/events',
    icon: '<path d="M5 3a2 2 0 012-2h6a2 2 0 012 2v11h-4v4H7v-4H3V3z" /><path d="M3 3h2v2H3V3z" />'
  },
  {
    name: 'Agenda',
    path: '/calendar',
    icon: '<path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />'
  },
  {
    name: 'Amis',
    path: '/friends',
    icon: '<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />'
  }
];

const isActive = (path: string): boolean => {
  if (path === '/events') {
    return route.path.startsWith('/events');
  }
  return route.path === path;
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

@keyframes party {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(10deg) scale(1.1); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
}

@media (max-width: 768px) {
  a, button {
    min-height: 44px;
    min-width: 44px;
  }
}
</style>