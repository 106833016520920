<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-2xl mx-auto">
      <div class="text-center mb-8">
        <!-- Logo animé -->
        <div class="relative w-32 h-32 mx-auto mb-6">
          <div class="absolute inset-0 animate-party">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-full h-full text-yellow-300">
              <path d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.25-4.5h-3M5.25 6h-3m15.75 3v3m-3-3v3m-6-3v3m-3-3v3m15.75 1.5a3 3 0 11-6 0 3 3 0 016 0zm-12 0a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
        </div>

        <h2 class="text-3xl font-extrabold text-white mb-2">
          Rejoignez la fête !
        </h2>
        <p class="text-white/80">
          Créez votre compte et commencez à organiser des moments inoubliables
        </p>
      </div>

      <div class="bg-white/10 backdrop-blur-lg rounded-2xl shadow-xl p-8">
        <UserForm
          v-model="form"
          :loading="authStore.loading"
          :error="authStore.error"
          :show-password="true"
          @submit="handleRegister"
        >
          <template #submit-text>
            {{ authStore.loading ? 'Création en cours...' : 'Créer mon compte' }}
          </template>
        </UserForm>

        <p class="text-center text-sm text-white/80 mt-6">
          Déjà inscrit ?
          <router-link to="/" class="font-medium text-yellow-300 hover:text-yellow-200 transition-colors">
            Connectez-vous
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import UserForm from '../components/UserForm.vue';

const router = useRouter();
const authStore = useAuthStore();

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  birthDate: '',
  mobile: '',
  address: '',
  postalCode: '',
  city: '',
  interests: [] as string[]
});

const handleRegister = async () => {
  try {
    await authStore.registerUser(form);
    router.push('/events');
  } catch (error) {
    console.error('Registration error:', error);
  }
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

@keyframes party {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(10deg) scale(1.1); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
}
</style>