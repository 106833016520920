import { defineStore } from 'pinia';
import { db, storage } from '../firebase/config';
import { 
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuthStore } from './auth';
import { useFriendStore } from './friends';
import { formatName } from '../utils/formatters';
import { ERROR_MESSAGES } from '../config/constants';
import type { Event, Friend, GiftSuggestion } from '../types';

export const useEventStore = defineStore('events', {
  state: () => ({
    events: [] as Event[],
    loading: false,
    error: null as string | null,
    deletingEventIds: new Set<string>()
  }),

  actions: {
    async fetchEvents() {
      this.loading = true;
      this.error = null;
      const authStore = useAuthStore();
      const friendStore = useFriendStore();
      
      try {
        if (!authStore.currentUser) throw new Error('User not authenticated');

        // Récupérer les événements de la base de données
        const q = query(
          collection(db, 'events'),
          where('createdBy', '==', authStore.currentUser.id)
        );
        
        const snapshot = await getDocs(q);
        const dbEvents = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            type: data.type,
            name: data.name,
            date: data.date,
            participants: data.participants || [],
            gift: data.gift || undefined,
            createdBy: data.createdBy,
            createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate().toISOString() : data.createdAt,
            updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate().toISOString() : data.updatedAt
          } as Event;
        });

        // Générer les événements d'anniversaire
        const birthdayEvents = this.generateBirthdayEvents(friendStore.friends);

        // Fusionner les événements
        this.events = [...dbEvents, ...birthdayEvents].sort((a, b) => 
          new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      } catch (error: any) {
        console.error('Error fetching events:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    generateBirthdayEvents(friends: Friend[]): Event[] {
      const authStore = useAuthStore();
      if (!authStore.currentUser) return [];

      const today = new Date();
      const nextYear = new Date(today.getFullYear() + 1, 11, 31);

      return friends
        .filter(friend => friend.birthDate)
        .map(friend => {
          const birthDate = new Date(friend.birthDate);
          let nextBirthday = new Date(
            today.getFullYear(),
            birthDate.getMonth(),
            birthDate.getDate()
          );

          if (nextBirthday < today) {
            nextBirthday.setFullYear(nextBirthday.getFullYear() + 1);
          }

          if (nextBirthday <= nextYear) {
            return {
              id: `birthday-${friend.id}-${nextBirthday.getFullYear()}`,
              type: 'birthday',
              name: formatName.fullName(friend.firstName, friend.lastName),
              date: nextBirthday.toISOString(),
              participants: [friend],
              createdBy: authStore.currentUser.id,
              createdAt: new Date().toISOString()
            } as Event;
          }
          return null;
        })
        .filter((event): event is Event => event !== null);
    },

    async createEvent(eventData: Omit<Event, 'id' | 'createdBy' | 'createdAt'>) {
      this.loading = true;
      this.error = null;
      const authStore = useAuthStore();

      try {
        if (!authStore.currentUser) throw new Error('User not authenticated');

        const docRef = await addDoc(collection(db, 'events'), {
          ...eventData,
          createdBy: authStore.currentUser.id,
          createdAt: serverTimestamp()
        });

        const newEvent: Event = {
          id: docRef.id,
          ...eventData,
          createdBy: authStore.currentUser.id,
          createdAt: new Date().toISOString()
        };

        this.events.push(newEvent);
        return newEvent;
      } catch (error: any) {
        console.error('Error creating event:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateEvent(eventData: Partial<Event> & { id: string }) {
      this.loading = true;
      this.error = null;

      try {
        const eventRef = doc(db, 'events', eventData.id);
        await updateDoc(eventRef, {
          ...eventData,
          updatedAt: serverTimestamp()
        });

        const index = this.events.findIndex(e => e.id === eventData.id);
        if (index !== -1) {
          this.events[index] = {
            ...this.events[index],
            ...eventData,
            updatedAt: new Date().toISOString()
          };
        }
      } catch (error: any) {
        console.error('Error updating event:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteEvent(eventId: string) {
      this.deletingEventIds.add(eventId);
      this.error = null;

      try {
        await deleteDoc(doc(db, 'events', eventId));
        this.events = this.events.filter(e => e.id !== eventId);
      } catch (error: any) {
        console.error('Error deleting event:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.deletingEventIds.delete(eventId);
      }
    },

    async addGiftSuggestion(eventId: string, suggestion: Omit<GiftSuggestion, 'id'>) {
      this.loading = true;
      this.error = null;

      try {
        const event = this.events.find(e => e.id === eventId);
        if (!event) throw new Error('Event not found');

        const updatedEvent = {
          ...event,
          gift: {
            budget: suggestion.price,
            suggestions: [{
              ...suggestion,
              id: Date.now().toString()
            }],
            contributions: []
          }
        };

        await this.updateEvent(updatedEvent);
      } catch (error: any) {
        console.error('Error adding gift suggestion:', error);
        this.error = error.message || ERROR_MESSAGES.UPLOAD.GENERIC;
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
});