import { defineStore } from 'pinia';
import { auth, db } from '../firebase/config';
import { 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail
} from 'firebase/auth';
import { doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import type { User } from '../types';
import { formatName } from '../utils/formatters';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    currentUser: null as User | null,
    loading: false,
    error: null as string | null,
    initialized: false,
    searchResults: [] as User[],
    searchLoading: false,
    searchError: null as string | null
  }),

  actions: {
    async init() {
      if (this.initialized) return;
      
      return new Promise<void>((resolve) => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          try {
            if (user) {
              const userDoc = await getDoc(doc(db, 'users', user.uid));
              if (userDoc.exists()) {
                const userData = userDoc.data();
                this.currentUser = {
                  id: user.uid,
                  email: userData.email,
                  firstName: formatName.firstName(userData.firstName),
                  lastName: formatName.lastName(userData.lastName),
                  birthDate: userData.birthDate,
                  mobile: userData.mobile,
                  address: userData.address,
                  postalCode: userData.postalCode,
                  city: userData.city,
                  interests: userData.interests || [],
                  termsAccepted: userData.termsAccepted || false,
                  privacyAccepted: userData.privacyAccepted || false
                };
              }
            } else {
              this.currentUser = null;
            }
          } catch (error) {
            console.error('Error initializing auth:', error);
            this.currentUser = null;
          } finally {
            this.initialized = true;
            resolve();
            unsubscribe();
          }
        });
      });
    },

    async searchUsers(searchTerm: string) {
      if (!searchTerm.trim()) {
        this.searchResults = [];
        return;
      }

      this.searchLoading = true;
      this.searchError = null;

      try {
        const searchTermLower = searchTerm.toLowerCase();
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        
        this.searchResults = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as User))
          .filter(user => 
            user.id !== this.currentUser?.id && (
              user.firstName.toLowerCase().includes(searchTermLower) ||
              user.lastName.toLowerCase().includes(searchTermLower) ||
              user.email.toLowerCase().includes(searchTermLower)
            )
          )
          .slice(0, 10); // Limit results
      } catch (error: any) {
        console.error('Error searching users:', error);
        this.searchError = 'Une erreur est survenue lors de la recherche';
        this.searchResults = [];
      } finally {
        this.searchLoading = false;
      }
    },

    async registerUser(userData: Omit<User, 'id'> & { password: string }) {
      this.loading = true;
      this.error = null;

      try {
        const methods = await fetchSignInMethodsForEmail(auth, userData.email);
        if (methods.length > 0) {
          throw new Error('Un compte existe déjà avec cette adresse email');
        }

        const { email, password, ...userInfo } = userData;
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        const user: User = {
          id: userCredential.user.uid,
          email,
          ...userInfo
        };
        
        await setDoc(doc(db, 'users', user.id), user);
        this.currentUser = user;
        return user;
      } catch (error: any) {
        console.error('Registration error:', error);
        
        if (error.code === 'auth/email-already-in-use') {
          this.error = 'Un compte existe déjà avec cette adresse email';
        } else if (error.code === 'auth/invalid-email') {
          this.error = 'Adresse email invalide';
        } else if (error.code === 'auth/operation-not-allowed') {
          this.error = 'La création de compte est temporairement désactivée';
        } else if (error.code === 'auth/weak-password') {
          this.error = 'Le mot de passe doit contenir au moins 6 caractères';
        } else {
          this.error = error.message || 'Une erreur est survenue lors de l\'inscription';
        }
        
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async login(email: string, password: string) {
      this.loading = true;
      this.error = null;

      try {
        await setPersistence(auth, browserLocalPersistence);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        
        const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.currentUser = {
            id: userCredential.user.uid,
            email: userData.email,
            firstName: formatName.firstName(userData.firstName),
            lastName: formatName.lastName(userData.lastName),
            birthDate: userData.birthDate,
            mobile: userData.mobile,
            address: userData.address,
            postalCode: userData.postalCode,
            city: userData.city,
            interests: userData.interests || [],
            termsAccepted: userData.termsAccepted || false,
            privacyAccepted: userData.privacyAccepted || false
          };
        }
      } catch (error: any) {
        console.error('Login error:', error);
        
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          this.error = 'Email ou mot de passe incorrect';
        } else if (error.code === 'auth/too-many-requests') {
          this.error = 'Trop de tentatives échouées. Veuillez réessayer plus tard.';
        } else if (error.code === 'auth/user-disabled') {
          this.error = 'Ce compte a été désactivé';
        } else {
          this.error = 'Une erreur est survenue lors de la connexion';
        }
        
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async logout() {
      try {
        await signOut(auth);
        this.currentUser = null;
        this.searchResults = [];
      } catch (error: any) {
        console.error('Logout error:', error);
        this.error = 'Une erreur est survenue lors de la déconnexion';
        throw error;
      }
    },

    async updateUserProfile(userData: Partial<User>) {
      if (!this.currentUser?.id) throw new Error('User not authenticated');
      
      this.loading = true;
      this.error = null;

      try {
        const userRef = doc(db, 'users', this.currentUser.id);
        await setDoc(userRef, userData, { merge: true });
        this.currentUser = { ...this.currentUser, ...userData };
      } catch (error: any) {
        console.error('Profile update error:', error);
        this.error = 'Une erreur est survenue lors de la mise à jour du profil';
        throw error;
      } finally {
        this.loading = false;
      }
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.currentUser,
    isInitialized: (state) => state.initialized
  }
});