import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBrDfmhm_9w8KormGGBH1ZOy13CkU--87c",
  authDomain: "fetami-918c3.firebaseapp.com", 
  projectId: "fetami-918c3",
  storageBucket: "fetami-918c3.firebasestorage.app",
  messagingSenderId: "565448770130",
  appId: "1:565448770130:web:bc16309673aaface31958b",
  measurementId: "G-WLFHEXWX7F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with persistence
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence).catch(console.error);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { auth, db, storage };
export default app;