```vue
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                Détails du cadeau
              </DialogTitle>

              <div v-if="event?.gift" class="space-y-6">
                <!-- Gift Info -->
                <div class="bg-gray-50 rounded-lg p-4">
                  <div class="flex justify-between items-center mb-4">
                    <h4 class="font-medium text-gray-900">Budget total</h4>
                    <span class="text-xl font-semibold text-purple-600">{{ event.gift.budget }}€</span>
                  </div>
                </div>

                <!-- Gift Suggestions -->
                <div v-if="event.gift.suggestions?.length" class="space-y-4">
                  <h4 class="font-medium text-gray-900">Suggestions</h4>
                  <div class="grid grid-cols-1 gap-4">
                    <div v-for="suggestion in event.gift.suggestions" :key="suggestion.id"
                         class="bg-gray-50 rounded-lg overflow-hidden">
                      <!-- Photos -->
                      <PhotoBrowser v-if="suggestion.photoUrl" :photos="[suggestion.photoUrl]" />
                      
                      <div class="p-4">
                        <h5 class="font-medium text-gray-900 mb-1">{{ suggestion.name }}</h5>
                        <p class="text-sm text-gray-600 mb-2">{{ suggestion.description }}</p>
                        <div class="flex justify-between items-center text-sm">
                          <span class="text-gray-600">Prix: {{ suggestion.price }}€</span>
                          <span class="text-gray-500">
                            Suggéré par {{ getSuggesterName(suggestion.suggestedBy) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-6 flex justify-end">
                <button
                  type="button"
                  @click="closeModal"
                  class="inline-flex justify-center rounded-md border border-transparent bg-purple-100 px-4 py-2 text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none"
                >
                  Fermer
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useAuthStore } from '../stores/auth';
import PhotoBrowser from './PhotoBrowser.vue';
import type { Event } from '../types';

const props = defineProps<{
  isOpen: boolean;
  event: Event | null;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const authStore = useAuthStore();

const getSuggesterName = (suggesterId: string): string => {
  return suggesterId === authStore.currentUser?.id ? 'vous' : 'un ami';
};

const closeModal = () => {
  emit('close');
};
</script>
```