<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                {{ selectedUser ? 'Détails de l\'utilisateur' : 'Rechercher des amis' }}
              </DialogTitle>

              <div v-if="selectedUser" class="mt-4">
                <div class="flex items-center space-x-4 mb-6">
                  <div class="w-16 h-16 rounded-full bg-purple-100 flex items-center justify-center text-purple-600 text-2xl font-semibold">
                    {{ selectedUser.firstName[0].toUpperCase() }}
                  </div>
                  <div>
                    <h4 class="text-xl font-semibold text-gray-900">
                      {{ formatName.fullName(selectedUser.firstName, selectedUser.lastName) }}
                    </h4>
                    <p class="text-gray-500">{{ selectedUser.city }}</p>
                  </div>
                </div>

                <div class="space-y-4">
                  <div class="bg-gray-50 rounded-lg p-4">
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <p class="text-sm text-gray-500">Date de naissance</p>
                        <p class="font-medium">{{ formatDate(selectedUser.birthDate) }}</p>
                      </div>
                      <div>
                        <p class="text-sm text-gray-500">Ville</p>
                        <p class="font-medium">{{ selectedUser.city }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="space-y-3">
                    <div class="flex items-center space-x-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                      </svg>
                      <span class="font-medium">{{ selectedUser.mobile || 'Non renseigné' }}</span>
                    </div>

                    <div class="flex items-center space-x-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                      </svg>
                      <span class="font-medium">{{ selectedUser.email || 'Non renseigné' }}</span>
                    </div>

                    <div class="flex items-center space-x-3">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                      </svg>
                      <span class="font-medium">{{ selectedUser.address || 'Non renseigné' }}</span>
                    </div>
                  </div>

                  <div class="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      @click="backToSearch"
                      class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                    >
                      Retour
                    </button>
                    <button
                      @click="addFriend(selectedUser)"
                      class="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700"
                    >
                      Ajouter en ami
                    </button>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="mb-4">
                  <input
                    type="text"
                    v-model="searchTerm"
                    placeholder="Rechercher par nom ou prénom..."
                    class="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                    @input="debounceSearch"
                  >
                </div>

                <div class="max-h-96 overflow-y-auto">
                  <div v-if="authStore.searchLoading" class="text-center py-4">
                    <div class="animate-spin h-8 w-8 mx-auto text-purple-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  </div>

                  <div v-else-if="authStore.searchResults.length === 0 && searchTerm" 
                       class="text-center py-4 text-gray-500">
                    Aucun utilisateur trouvé
                  </div>

                  <div v-else class="space-y-2">
                    <button
                      v-for="user in authStore.searchResults" 
                      :key="user.id"
                      class="w-full flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg transition-colors text-left"
                      @click="showUserDetails(user)"
                    >
                      <div class="flex items-center space-x-3">
                        <div class="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center text-purple-600 font-semibold">
                          {{ user.firstName[0].toUpperCase() }}
                        </div>
                        <div>
                          <h4 class="font-medium text-gray-900">
                            {{ formatName.fullName(user.firstName, user.lastName) }}
                          </h4>
                          <p class="text-sm text-gray-500">{{ user.city }}</p>
                        </div>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="mt-6 flex justify-end">
                  <button
                    type="button"
                    @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-purple-900 bg-purple-100 rounded-md hover:bg-purple-200"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useAuthStore } from '../stores/auth';
import { useFriendStore } from '../stores/friends';
import { formatName, formatDate } from '../utils/formatters';
import type { User } from '../types';

const props = defineProps<{
  isOpen: boolean
}>();

const emit = defineEmits<{
  (e: 'close'): void
}>();

const authStore = useAuthStore();
const friendStore = useFriendStore();
const searchTerm = ref('');
const selectedUser = ref<User | null>(null);
let searchTimeout: NodeJS.Timeout;

const debounceSearch = () => {
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    authStore.searchUsers(searchTerm.value);
  }, 300);
};

const showUserDetails = (user: User) => {
  selectedUser.value = user;
};

const backToSearch = () => {
  selectedUser.value = null;
};

const addFriend = async (user: User) => {
  try {
    await friendStore.addFriend({
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.birthDate,
      mobile: user.mobile || null,
      email: user.email || null
    });
    closeModal();
  } catch (error) {
    console.error('Erreur lors de l\'ajout de l\'ami:', error);
  }
};

const closeModal = () => {
  searchTerm.value = '';
  selectedUser.value = null;
  authStore.searchResults = [];
  emit('close');
};
</script>