// Formatage des noms
export const formatName = {
  firstName: (name: string): string => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  },
  
  lastName: (name: string): string => {
    if (!name) return '';
    return name.toUpperCase();
  },
  
  fullName: (firstName: string, lastName: string): string => {
    return `${formatName.firstName(firstName)} ${formatName.lastName(lastName)}`;
  }
};

// Formatage des dates
export const formatDate = (date: string | Date): string => {
  if (!date) return '';
  const d = new Date(date);
  return d.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};