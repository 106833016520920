```vue
<template>
  <div class="space-y-4">
    <label class="block text-sm font-medium text-white/90 mb-2">
      Centres d'intérêt
    </label>

    <!-- Selected Interests -->
    <div class="flex flex-wrap gap-2 mb-4">
      <div v-for="interest in modelValue" 
           :key="interest"
           class="group flex items-center bg-yellow-300 text-purple-700 px-3 py-1 rounded-full text-sm font-medium
                  hover:bg-yellow-200 transition-colors">
        {{ interest }}
        <button @click="removeInterest(interest)"
                class="ml-2 text-purple-700/70 hover:text-purple-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>

    <!-- Suggested Interests -->
    <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
      <div v-for="interest in suggestedInterests" 
           :key="interest"
           class="relative flex items-center group hover:transform hover:scale-105 transition-all">
        <button @click="addInterest(interest)"
                :disabled="modelValue.includes(interest)"
                class="w-full p-3 text-sm text-white bg-white/20 rounded-lg transition-all
                       hover:bg-white/30 disabled:opacity-50 disabled:cursor-not-allowed">
          {{ interest }}
        </button>
      </div>
    </div>

    <!-- Custom Interest Input -->
    <div class="relative">
      <input
        v-model="newInterest"
        type="text"
        placeholder="Ajouter un centre d'intérêt personnalisé"
        class="w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white 
               focus:border-yellow-300 focus:ring focus:ring-yellow-300/50 pr-12"
        @keyup.enter="addCustomInterest"
      >
      <button
        @click="addCustomInterest"
        :disabled="!newInterest.trim()"
        class="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-white/70 hover:text-white
               disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  modelValue: string[];
  suggestedInterests: string[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

const newInterest = ref('');

const addInterest = (interest: string) => {
  if (!props.modelValue.includes(interest)) {
    emit('update:modelValue', [...props.modelValue, interest]);
  }
};

const removeInterest = (interest: string) => {
  emit('update:modelValue', props.modelValue.filter(i => i !== interest));
};

const addCustomInterest = () => {
  const interest = newInterest.value.trim();
  if (interest && !props.modelValue.includes(interest)) {
    emit('update:modelValue', [...props.modelValue, interest]);
    newInterest.value = '';
  }
};
</script>
```