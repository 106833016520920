<template>
  <div class="relative w-full h-full">
    <!-- Large Center Star -->
    <div class="absolute inset-0 animate-spin-slow">
      <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300">
          <path fill="currentColor" d="M12 0l2.832 9.168L24 12l-9.168 2.832L12 24l-2.832-9.168L0 12l9.168-2.832z"/>
        </svg>
      </div>
    </div>

    <!-- Orbiting Stars -->
    <div class="absolute inset-0">
      <div class="absolute top-0 left-1/2 -translate-x-1/2 w-6 h-6 animate-orbit-1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300">
          <path fill="currentColor" d="M12 0l2.832 9.168L24 12l-9.168 2.832L12 24l-2.832-9.168L0 12l9.168-2.832z"/>
        </svg>
      </div>
      
      <div class="absolute top-1/2 right-0 -translate-y-1/2 w-6 h-6 animate-orbit-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300">
          <path fill="currentColor" d="M12 0l2.832 9.168L24 12l-9.168 2.832L12 24l-2.832-9.168L0 12l9.168-2.832z"/>
        </svg>
      </div>
      
      <div class="absolute bottom-0 left-1/2 -translate-x-1/2 w-6 h-6 animate-orbit-3">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300">
          <path fill="currentColor" d="M12 0l2.832 9.168L24 12l-9.168 2.832L12 24l-2.832-9.168L0 12l9.168-2.832z"/>
        </svg>
      </div>
      
      <div class="absolute top-1/2 left-0 -translate-y-1/2 w-6 h-6 animate-orbit-4">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-full h-full text-yellow-300">
          <path fill="currentColor" d="M12 0l2.832 9.168L24 12l-9.168 2.832L12 24l-2.832-9.168L0 12l9.168-2.832z"/>
        </svg>
      </div>
    </div>

    <!-- Small Sparkles -->
    <div class="absolute inset-0">
      <div class="absolute -top-4 -left-4 animate-sparkle-1">
        <div class="w-2 h-2 bg-yellow-300 rounded-full"></div>
      </div>
      <div class="absolute -top-6 left-1/2 -translate-x-1/2 animate-sparkle-2">
        <div class="w-2 h-2 bg-yellow-300 rounded-full"></div>
      </div>
      <div class="absolute -top-4 -right-4 animate-sparkle-3">
        <div class="w-2 h-2 bg-yellow-300 rounded-full"></div>
      </div>
      <div class="absolute top-1/2 -right-6 -translate-y-1/2 animate-sparkle-4">
        <div class="w-2 h-2 bg-yellow-300 rounded-full"></div>
      </div>
    </div>

    <!-- Glowing Effect -->
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-yellow-300/30 rounded-full filter blur-xl animate-pulse-slow"></div>
    </div>
  </div>
</template>

<style scoped>
.animate-spin-slow {
  animation: spin 8s linear infinite;
}

.animate-orbit-1 {
  animation: orbit1 6s linear infinite;
}

.animate-orbit-2 {
  animation: orbit2 6s linear infinite;
}

.animate-orbit-3 {
  animation: orbit3 6s linear infinite;
}

.animate-orbit-4 {
  animation: orbit4 6s linear infinite;
}

.animate-sparkle-1 {
  animation: sparkle 2s ease-in-out infinite;
}

.animate-sparkle-2 {
  animation: sparkle 2s ease-in-out infinite 0.5s;
}

.animate-sparkle-3 {
  animation: sparkle 2s ease-in-out infinite 1s;
}

.animate-sparkle-4 {
  animation: sparkle 2s ease-in-out infinite 1.5s;
}

.animate-pulse-slow {
  animation: pulse 4s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes orbit1 {
  from { transform: rotate(0deg) translateY(-100%) rotate(0deg); }
  to { transform: rotate(360deg) translateY(-100%) rotate(-360deg); }
}

@keyframes orbit2 {
  from { transform: rotate(90deg) translateY(-100%) rotate(-90deg); }
  to { transform: rotate(450deg) translateY(-100%) rotate(-450deg); }
}

@keyframes orbit3 {
  from { transform: rotate(180deg) translateY(-100%) rotate(-180deg); }
  to { transform: rotate(540deg) translateY(-100%) rotate(-540deg); }
}

@keyframes orbit4 {
  from { transform: rotate(270deg) translateY(-100%) rotate(-270deg); }
  to { transform: rotate(630deg) translateY(-100%) rotate(-630deg); }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
}
</style>