```vue
<template>
  <div class="relative">
    <!-- Search Input -->
    <div class="group">
      <label class="block text-sm font-medium text-white mb-2">
        Pour qui est l'événement ?
      </label>
      <input
        type="text"
        v-model="searchTerm"
        placeholder="Rechercher un ami..."
        class="w-full rounded-lg bg-white/20 border-transparent text-white 
               placeholder-white/50 focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
        @input="debounceSearch"
      >
    </div>

    <!-- Results Dropdown -->
    <div v-if="searchTerm && searchResults.length > 0"
         class="absolute z-10 mt-1 w-full bg-white/10 backdrop-blur-lg rounded-lg border border-white/20 shadow-xl overflow-hidden">
      <div class="max-h-48 overflow-y-auto">
        <button
          v-for="friend in searchResults"
          :key="friend.id"
          @click="selectFriend(friend)"
          class="w-full px-4 py-2 text-left hover:bg-white/10 transition-colors"
        >
          <div class="flex items-center">
            <div class="flex-1">
              <div class="text-white font-medium">
                {{ formatName.fullName(friend.firstName, friend.lastName) }}
              </div>
              <div class="text-white/60 text-sm">
                {{ friend.email || friend.mobile || 'Aucun contact' }}
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>

    <!-- No Results -->
    <div v-else-if="searchTerm && !loading && searchResults.length === 0"
         class="absolute z-10 mt-1 w-full bg-white/10 backdrop-blur-lg rounded-lg border border-white/20 p-4 text-center">
      <p class="text-white/80">Aucun ami trouvé</p>
    </div>

    <!-- Selected Friend -->
    <div v-if="modelValue" class="mt-4 p-4 bg-white/10 backdrop-blur-sm rounded-lg">
      <div class="flex items-center justify-between">
        <div>
          <div class="text-white font-medium">
            {{ formatName.fullName(modelValue.firstName, modelValue.lastName) }}
          </div>
          <div class="text-white/60 text-sm">
            {{ modelValue.email || modelValue.mobile || 'Aucun contact' }}
          </div>
        </div>
        <button
          @click="clearSelection"
          class="p-1 text-white/60 hover:text-white transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useFriendStore } from '../stores/friends';
import { formatName } from '../utils/formatters';
import type { Friend } from '../types';

const props = defineProps<{
  modelValue: Friend | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: Friend | null): void;
}>();

const friendStore = useFriendStore();
const searchTerm = ref('');
const searchResults = ref<Friend[]>([]);
const loading = ref(false);
let searchTimeout: NodeJS.Timeout;

const debounceSearch = () => {
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    if (searchTerm.value.trim()) {
      searchResults.value = friendStore.friends.filter(friend => 
        friend.firstName.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        friend.lastName.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    } else {
      searchResults.value = [];
    }
  }, 300);
};

const selectFriend = (friend: Friend) => {
  emit('update:modelValue', friend);
  searchTerm.value = '';
  searchResults.value = [];
};

const clearSelection = () => {
  emit('update:modelValue', null);
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}
</style>
```