```vue
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 mb-4">
                Politique de Confidentialité
              </DialogTitle>

              <div class="max-h-96 overflow-y-auto mb-6 space-y-4 text-sm text-gray-600">
                <p>Dernière mise à jour : {{ new Date().toLocaleDateString() }}</p>

                <h4 class="font-medium text-gray-900">1. Collecte des données</h4>
                <p>
                  Nous collectons les informations que vous nous fournissez directement :
                </p>
                <ul class="list-disc pl-5 space-y-1">
                  <li>Informations d'identification (nom, prénom, email)</li>
                  <li>Informations de contact (adresse, téléphone)</li>
                  <li>Données relatives aux événements que vous créez</li>
                  <li>Informations sur vos centres d'intérêt</li>
                </ul>

                <h4 class="font-medium text-gray-900">2. Utilisation des données</h4>
                <p>
                  Nous utilisons vos données pour :
                </p>
                <ul class="list-disc pl-5 space-y-1">
                  <li>Fournir et améliorer nos services</li>
                  <li>Personnaliser votre expérience</li>
                  <li>Communiquer avec vous</li>
                  <li>Assurer la sécurité de votre compte</li>
                </ul>

                <h4 class="font-medium text-gray-900">3. Protection des données</h4>
                <p>
                  Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données personnelles
                  contre tout accès, modification, divulgation ou destruction non autorisée.
                </p>

                <h4 class="font-medium text-gray-900">4. Partage des données</h4>
                <p>
                  Nous ne partageons vos données personnelles qu'avec votre consentement explicite,
                  sauf si requis par la loi.
                </p>

                <h4 class="font-medium text-gray-900">5. Vos droits</h4>
                <p>
                  Vous disposez des droits suivants concernant vos données personnelles :
                </p>
                <ul class="list-disc pl-5 space-y-1">
                  <li>Droit d'accès</li>
                  <li>Droit de rectification</li>
                  <li>Droit à l'effacement</li>
                  <li>Droit à la limitation du traitement</li>
                  <li>Droit à la portabilité des données</li>
                </ul>

                <h4 class="font-medium text-gray-900">6. Conservation des données</h4>
                <p>
                  Nous conservons vos données personnelles aussi longtemps que nécessaire pour
                  fournir nos services ou respecter nos obligations légales.
                </p>

                <h4 class="font-medium text-gray-900">7. Modifications</h4>
                <p>
                  Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
                  Les modifications entrent en vigueur dès leur publication.
                </p>
              </div>

              <div class="mt-6 flex flex-col gap-4">
                <div class="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="accept-privacy"
                    v-model="accepted"
                    class="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                  >
                  <label for="accept-privacy" class="text-sm text-gray-700">
                    J'ai lu et j'accepte la politique de confidentialité
                  </label>
                </div>

                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    @click="confirmAcceptance"
                    :disabled="!accepted"
                    class="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Accepter et continuer
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'accept'): void;
}>();

const accepted = ref(false);

const closeModal = () => {
  accepted.value = false;
  emit('close');
};

const confirmAcceptance = () => {
  if (accepted.value) {
    emit('accept');
    closeModal();
  }
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(107, 114, 128, 0.3) transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: rgba(107, 114, 128, 0.3);
  border-radius: 3px;
}
</style>
```