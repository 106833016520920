```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-12 px-4">
    <div class="max-w-3xl mx-auto">
      <!-- Header -->
      <div class="text-center mb-12">
        <div class="relative w-24 h-24 mx-auto mb-6">
          <div class="absolute inset-0 animate-party">
            <div class="w-full h-full rounded-full bg-yellow-300 flex items-center justify-center text-3xl font-bold text-purple-700">
              {{ authStore.currentUser?.firstName.charAt(0).toUpperCase() }}
            </div>
          </div>
        </div>
        <h1 class="text-3xl font-bold text-white mb-2">Votre profil</h1>
        <p class="text-white/80">Gérez vos informations personnelles</p>
      </div>

      <!-- Terms and Privacy Warning -->
      <div v-if="!authStore.currentUser?.termsAccepted || !authStore.currentUser?.privacyAccepted" 
           class="mb-8 rounded-lg bg-yellow-400/20 backdrop-blur p-4 border border-yellow-300/50">
        <div class="space-y-2">
          <div v-if="!authStore.currentUser?.termsAccepted">
            <p class="text-sm text-white">
              Pour continuer à utiliser Fêtup, veuillez accepter nos conditions générales d'utilisation.
              <button @click="showTermsModal = true" class="ml-2 underline hover:text-yellow-300">
                Lire et accepter les CGU
              </button>
            </p>
          </div>
          <div v-if="!authStore.currentUser?.privacyAccepted">
            <p class="text-sm text-white">
              Pour continuer à utiliser Fêtup, veuillez accepter notre politique de confidentialité.
              <button @click="showPrivacyModal = true" class="ml-2 underline hover:text-yellow-300">
                Lire et accepter la politique de confidentialité
              </button>
            </p>
          </div>
        </div>
      </div>

      <!-- Form -->
      <form @submit.prevent="updateProfile" class="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20">
        <div class="space-y-6">
          <!-- Personal Information -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Prénom
              </label>
              <input
                v-model="form.firstName"
                type="text"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Nom
              </label>
              <input
                v-model="form.lastName"
                type="text"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
          </div>

          <div class="group">
            <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
              Email
            </label>
            <input
              v-model="form.email"
              type="email"
              required
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Date de naissance
              </label>
              <input
                v-model="form.birthDate"
                type="date"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Mobile
              </label>
              <input
                v-model="form.mobile"
                type="tel"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
          </div>

          <!-- Address -->
          <div class="group">
            <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
              Adresse
            </label>
            <input
              v-model="form.address"
              type="text"
              required
              class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Code postal
              </label>
              <input
                v-model="form.postalCode"
                type="text"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
            <div class="group">
              <label class="block text-sm font-medium text-white/90 group-focus-within:text-yellow-300 transition-colors">
                Ville
              </label>
              <input
                v-model="form.city"
                type="text"
                required
                class="mt-1 block w-full rounded-lg bg-white/20 border-transparent placeholder-white/50 text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
              >
            </div>
          </div>

          <!-- Interests -->
          <InterestsManager
            v-model="form.interests"
            :suggested-interests="suggestedInterests"
          />

          <!-- Error Message -->
          <div v-if="error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
            <p class="text-sm text-white">{{ error }}</p>
          </div>

          <!-- Success Message -->
          <div v-if="updateSuccess" class="rounded-lg bg-green-400/20 backdrop-blur p-4 border border-green-500/50">
            <p class="text-sm text-white">Profil mis à jour avec succès !</p>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end space-x-4 pt-6">
            <button
              type="button"
              @click="resetForm"
              class="px-6 py-3 bg-white/20 text-white rounded-lg font-semibold hover:bg-white/30 
                     transition-all duration-200"
              :disabled="loading"
            >
              Annuler
            </button>
            <button
              type="submit"
              class="px-6 py-3 bg-yellow-300 text-purple-700 rounded-lg font-semibold hover:bg-yellow-200 
                     transform hover:scale-105 transition-all duration-200 shadow-lg disabled:opacity-50"
              :disabled="loading"
            >
              {{ loading ? 'Enregistrement...' : 'Enregistrer les modifications' }}
            </button>
          </div>
        </div>
      </form>

      <!-- Terms of Service Modal -->
      <TermsOfService
        :is-open="showTermsModal"
        @close="showTermsModal = false"
        @accept="acceptTerms"
      />

      <!-- Privacy Policy Modal -->
      <PrivacyPolicy
        :is-open="showPrivacyModal"
        @close="showPrivacyModal = false"
        @accept="acceptPrivacy"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useAuthStore } from '../stores/auth';
import TermsOfService from '../components/TermsOfService.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import InterestsManager from '../components/InterestsManager.vue';
import type { User } from '../types';

const authStore = useAuthStore();
const loading = ref(false);
const error = ref<string | null>(null);
const updateSuccess = ref(false);
const showTermsModal = ref(false);
const showPrivacyModal = ref(false);

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  birthDate: '',
  mobile: '',
  address: '',
  postalCode: '',
  city: '',
  interests: [] as string[]
});

const suggestedInterests = [
  'Musique',
  'Cinéma',
  'Sport',
  'Lecture',
  'Cuisine',
  'Voyages',
  'Art',
  'Jeux vidéo',
  'Technologie',
  'Nature',
  'Photographie',
  'Mode',
  'Danse',
  'Théâtre',
  'Jardinage'
];

onMounted(() => {
  if (authStore.currentUser) {
    form.value = {
      firstName: authStore.currentUser.firstName,
      lastName: authStore.currentUser.lastName,
      email: authStore.currentUser.email,
      birthDate: authStore.currentUser.birthDate,
      mobile: authStore.currentUser.mobile,
      address: authStore.currentUser.address,
      postalCode: authStore.currentUser.postalCode,
      city: authStore.currentUser.city,
      interests: [...authStore.currentUser.interests]
    };
  }
});

const resetForm = () => {
  if (authStore.currentUser) {
    form.value = {
      firstName: authStore.currentUser.firstName,
      lastName: authStore.currentUser.lastName,
      email: authStore.currentUser.email,
      birthDate: authStore.currentUser.birthDate,
      mobile: authStore.currentUser.mobile,
      address: authStore.currentUser.address,
      postalCode: authStore.currentUser.postalCode,
      city: authStore.currentUser.city,
      interests: [...authStore.currentUser.interests]
    };
  }
  error.value = null;
  updateSuccess.value = false;
};

const updateProfile = async () => {
  if (!authStore.currentUser) return;
  
  loading.value = true;
  error.value = null;
  updateSuccess.value = false;

  try {
    await authStore.updateUserProfile({
      ...form.value,
      id: authStore.currentUser.id,
      termsAccepted: authStore.currentUser.termsAccepted,
      privacyAccepted: authStore.currentUser.privacyAccepted
    } as User);
    
    updateSuccess.value = true;
    setTimeout(() => {
      updateSuccess.value = false;
    }, 3000);
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de la mise à jour du profil';
  } finally {
    loading.value = false;
  }
};

const acceptTerms = async () => {
  if (!authStore.currentUser) return;
  
  loading.value = true;
  try {
    await authStore.updateUserProfile({
      ...authStore.currentUser,
      termsAccepted: true
    });
    showTermsModal.value = false;
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de l\'acceptation des CGU';
  } finally {
    loading.value = false;
  }
};

const acceptPrivacy = async () => {
  if (!authStore.currentUser) return;
  
  loading.value = true;
  try {
    await authStore.updateUserProfile({
      ...authStore.currentUser,
      privacyAccepted: true
    });
    showPrivacyModal.value = false;
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de l\'acceptation de la politique de confidentialité';
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

@keyframes party {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(10deg) scale(1.1); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
```