import { defineStore } from 'pinia';
import { db } from '../firebase/config';
import { 
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { useAuthStore } from './auth';
import type { Friend } from '../types';
import { formatName } from '../utils/formatters';

export const useFriendStore = defineStore('friends', {
  state: () => ({
    friends: [] as Friend[],
    loading: false,
    error: null as string | null
  }),

  actions: {
    async fetchFriends() {
      this.loading = true;
      this.error = null;
      const authStore = useAuthStore();
      
      try {
        if (!authStore.currentUser) throw new Error('User not authenticated');

        const q = query(
          collection(db, 'friends'),
          where('userId', '==', authStore.currentUser.id)
        );
        
        const snapshot = await getDocs(q);
        this.friends = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.birthDate,
            mobile: data.mobile,
            email: data.email,
            userId: data.userId,
            createdAt: data.createdAt instanceof Timestamp ? data.createdAt.toDate().toISOString() : null,
            updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt.toDate().toISOString() : null
          } as Friend;
        });
      } catch (error: any) {
        console.error('Error fetching friends:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async addFriend(friendData: Omit<Friend, 'id' | 'userId' | 'createdAt' | 'updatedAt'>) {
      this.loading = true;
      this.error = null;
      const authStore = useAuthStore();
      
      try {
        if (!authStore.currentUser) throw new Error('User not authenticated');

        const formattedData = {
          firstName: formatName.firstName(friendData.firstName),
          lastName: formatName.lastName(friendData.lastName),
          birthDate: friendData.birthDate,
          mobile: friendData.mobile || null,
          email: friendData.email || null,
          userId: authStore.currentUser.id,
          createdAt: serverTimestamp()
        };

        const docRef = await addDoc(collection(db, 'friends'), formattedData);
        
        const newFriend: Friend = {
          id: docRef.id,
          ...formattedData,
          createdAt: new Date().toISOString(),
          updatedAt: null
        };

        this.friends.push(newFriend);
        return newFriend;
      } catch (error: any) {
        console.error('Error adding friend:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async updateFriend(friend: Friend) {
      this.loading = true;
      this.error = null;
      const authStore = useAuthStore();
      
      try {
        if (!authStore.currentUser) throw new Error('User not authenticated');

        const friendRef = doc(db, 'friends', friend.id);
        const updateData = {
          firstName: formatName.firstName(friend.firstName),
          lastName: formatName.lastName(friend.lastName),
          birthDate: friend.birthDate,
          mobile: friend.mobile || null,
          email: friend.email || null,
          updatedAt: serverTimestamp()
        };

        await updateDoc(friendRef, updateData);

        const index = this.friends.findIndex(f => f.id === friend.id);
        if (index !== -1) {
          this.friends[index] = {
            ...friend,
            ...updateData,
            updatedAt: new Date().toISOString()
          };
        }
      } catch (error: any) {
        console.error('Error updating friend:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async deleteFriend(friendId: string) {
      this.loading = true;
      this.error = null;
      try {
        await deleteDoc(doc(db, 'friends', friendId));
        this.friends = this.friends.filter(f => f.id !== friendId);
      } catch (error: any) {
        console.error('Error deleting friend:', error);
        this.error = error.message;
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
});