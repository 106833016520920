<template>
  <div class="relative w-full h-full">
    <!-- Main Gift Box -->
    <div class="absolute inset-0 animate-float">
      <!-- Box Base -->
      <div class="absolute bottom-0 w-full h-3/5 bg-yellow-300 rounded-lg shadow-lg"></div>
      <!-- Box Lid -->
      <div class="absolute top-0 w-full h-2/5 bg-yellow-300 rounded-t-lg shadow-lg">
        <!-- Ribbon -->
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 w-full h-4 bg-yellow-400"></div>
        <div class="absolute top-0 left-1/2 -translate-x-1/2 w-4 h-full bg-yellow-400"></div>
        <!-- Bow -->
        <div class="absolute -top-4 left-1/2 -translate-x-1/2">
          <div class="relative w-12 h-8">
            <div class="absolute left-0 w-6 h-8 bg-yellow-400 rounded-full transform -rotate-45"></div>
            <div class="absolute right-0 w-6 h-8 bg-yellow-400 rounded-full transform rotate-45"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sparkles -->
    <div class="absolute inset-0">
      <div class="absolute -top-4 -left-4 animate-sparkle-1">
        <div class="w-2 h-2 bg-white rounded-full"></div>
      </div>
      <div class="absolute -top-6 left-1/2 animate-sparkle-2">
        <div class="w-2 h-2 bg-white rounded-full"></div>
      </div>
      <div class="absolute -bottom-4 -right-4 animate-sparkle-3">
        <div class="w-2 h-2 bg-white rounded-full"></div>
      </div>
      <div class="absolute -bottom-6 left-1/4 animate-sparkle-4">
        <div class="w-2 h-2 bg-white rounded-full"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-sparkle-1 {
  animation: sparkle 2s ease-in-out infinite;
}

.animate-sparkle-2 {
  animation: sparkle 2s ease-in-out infinite 0.5s;
}

.animate-sparkle-3 {
  animation: sparkle 2s ease-in-out infinite 1s;
}

.animate-sparkle-4 {
  animation: sparkle 2s ease-in-out infinite 1.5s;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
}
</style>