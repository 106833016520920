```vue
<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-8 px-4">
    <div class="max-w-7xl mx-auto">
      <!-- En-tête -->
      <div class="text-center mb-12">
        <div class="relative w-24 h-24 mx-auto mb-6">
          <div class="absolute inset-0 animate-party">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
                 class="w-full h-full text-yellow-300">
              <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <h1 class="text-4xl font-bold text-white mb-4">Votre agenda</h1>
        <p class="text-white/80 text-lg mb-8">Tous vos événements à venir</p>
      </div>

      <!-- Filtres -->
      <div class="mb-8">
        <div class="flex flex-wrap gap-2 justify-center">
          <button
            v-for="type in eventTypes"
            :key="type.value"
            @click="toggleType(type.value)"
            :class="[
              'px-4 py-2 rounded-full font-medium transition-all duration-200',
              selectedTypes.includes(type.value) 
                ? getEventTypeClass(type.value)
                : 'bg-white/10 text-white hover:bg-white/20'
            ]"
          >
            {{ type.label }}
          </button>
        </div>
      </div>

      <!-- Timeline des événements -->
      <div class="relative">
        <!-- Ligne verticale -->
        <div class="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-white/20"></div>

        <!-- Événements -->
        <div class="space-y-12">
          <TransitionGroup 
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform -translate-y-4 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform translate-y-0 opacity-100"
            leave-to-class="transform -translate-y-4 opacity-0"
          >
            <div v-for="event in filteredEvents" :key="event.id"
                 class="relative">
              <!-- Point sur la timeline -->
              <div class="absolute left-1/2 transform -translate-x-1/2 -translate-y-4 w-4 h-4 rounded-full"
                   :class="getEventTypeClass(event.type)">
              </div>

              <!-- Contenu de l'événement -->
              <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <!-- Date -->
                <div class="flex justify-end md:justify-end items-center pr-8 md:pr-16">
                  <div class="text-right">
                    <div class="text-xl font-bold text-white">
                      {{ formatDate(event.date) }}
                    </div>
                    <div class="text-white/70">
                      {{ new Date(event.date).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) }}
                    </div>
                  </div>
                </div>

                <!-- Détails -->
                <div class="pl-8 md:pl-16">
                  <div class="bg-white/10 backdrop-blur-lg rounded-2xl p-6 border border-white/20 shadow-xl">
                    <div :class="getEventTypeBadgeClass(event.type)" 
                         class="inline-block px-3 py-1 rounded-full text-sm font-medium mb-3">
                      {{ getEventTypeLabel(event.type) }}
                    </div>
                    
                    <h3 class="text-xl font-bold text-white mb-2">{{ event.name }}</h3>
                    
                    <div v-if="event.gift" class="flex items-center text-white/80">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z" clip-rule="evenodd" />
                        <path d="M9 11H3v5a2 2 0 002 2h4v-7zm2 7h4a2 2 0 002-2v-5h-6v7z" />
                      </svg>
                      Budget cadeau: {{ event.gift.budget }}€
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>

      <!-- Message si aucun événement -->
      <div v-if="filteredEvents.length === 0" 
           class="text-center py-12 bg-white/10 backdrop-blur-lg rounded-2xl border border-white/20 mt-12">
        <div class="w-24 h-24 mx-auto mb-6 animate-party">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" 
               class="w-full h-full text-yellow-300">
            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clip-rule="evenodd" />
          </svg>
        </div>
        <h3 class="text-xl font-medium text-white mb-2">Aucun événement à venir</h3>
        <p class="text-white/80">Commencez par créer votre premier événement !</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useEventStore } from '../stores/events';
import { formatDate } from '../utils/formatters';
import type { Event } from '../types';

const eventStore = useEventStore();
const selectedTypes = ref<Event['type'][]>([]);

const eventTypes = [
  { value: 'birthday', label: 'Anniversaires' },
  { value: 'party', label: 'Soirées' },
  { value: 'christmas', label: 'Noël' },
  { value: 'retirement', label: 'Retraites' },
  { value: 'newYear', label: 'Nouvel An' },
  { value: 'dinner', label: 'Repas' },
  { value: 'other', label: 'Autres' }
];

onMounted(async () => {
  await eventStore.fetchEvents();
});

const toggleType = (type: Event['type']) => {
  const index = selectedTypes.value.indexOf(type);
  if (index === -1) {
    selectedTypes.value.push(type);
  } else {
    selectedTypes.value.splice(index, 1);
  }
};

const filteredEvents = computed(() => {
  let events = [...eventStore.events];
  
  // Filtrer par type si des types sont sélectionnés
  if (selectedTypes.value.length > 0) {
    events = events.filter(event => selectedTypes.value.includes(event.type));
  }
  
  // Trier par date
  return events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
});

const getEventTypeClass = (type: Event['type']) => {
  const classes = {
    birthday: 'bg-pink-500 shadow-lg shadow-pink-500/50',
    party: 'bg-purple-500 shadow-lg shadow-purple-500/50',
    christmas: 'bg-red-500 shadow-lg shadow-red-500/50',
    retirement: 'bg-blue-500 shadow-lg shadow-blue-500/50',
    newYear: 'bg-green-500 shadow-lg shadow-green-500/50',
    dinner: 'bg-orange-500 shadow-lg shadow-orange-500/50',
    other: 'bg-gray-500 shadow-lg shadow-gray-500/50'
  };
  return classes[type];
};

const getEventTypeBadgeClass = (type: Event['type']) => {
  const classes = {
    birthday: 'bg-pink-500/30 text-pink-200 border border-pink-500',
    party: 'bg-purple-500/30 text-purple-200 border border-purple-500',
    christmas: 'bg-red-500/30 text-red-200 border border-red-500',
    retirement: 'bg-blue-500/30 text-blue-200 border border-blue-500',
    newYear: 'bg-green-500/30 text-green-200 border border-green-500',
    dinner: 'bg-orange-500/30 text-orange-200 border border-orange-500',
    other: 'bg-gray-500/30 text-gray-200 border border-gray-500'
  };
  return classes[type];
};

const getEventTypeLabel = (type: Event['type']) => {
  const labels = {
    birthday: 'Anniversaire',
    party: 'Soirée',
    christmas: 'Noël',
    retirement: 'Retraite',
    newYear: 'Nouvel An',
    dinner: 'Repas',
    other: 'Autre'
  };
  return labels[type];
};
</script>

<style scoped>
.animate-party {
  animation: party 3s infinite;
}

@keyframes party {
  0% { transform: rotate(0deg) scale(1); }
  25% { transform: rotate(10deg) scale(1.1); }
  50% { transform: rotate(0deg) scale(1); }
  75% { transform: rotate(-10deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1); }
}
</style>
```