<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-600 via-pink-500 to-orange-400 py-12 px-4">
    <div class="max-w-2xl mx-auto">
      <!-- Header -->
      <div class="text-center mb-8">
        <h1 class="text-3xl font-bold text-white">Modifier l'événement</h1>
        <p class="text-white/80 mt-2">Modifiez les détails de votre événement</p>
      </div>

      <!-- Form -->
      <form @submit.prevent="updateEvent" class="bg-white/10 backdrop-blur-lg rounded-2xl p-8 shadow-xl border border-white/20">
        <div class="space-y-6">
          <!-- Event Type -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Type d'événement</label>
            <select
              v-model="form.type"
              required
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
              <option value="" disabled>Sélectionnez un type</option>
              <option value="birthday">Anniversaire</option>
              <option value="party">Soirée</option>
              <option value="christmas">Noël</option>
              <option value="retirement">Retraite</option>
              <option value="newYear">Nouvel An</option>
              <option value="dinner">Repas</option>
              <option value="other">Autre</option>
            </select>
          </div>

          <!-- Person Info -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label class="block text-sm font-medium text-white mb-2">Prénom</label>
              <input 
                v-model="form.firstName"
                type="text"
                required
                class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
                placeholder="Ex: Marie"
              >
            </div>
            <div>
              <label class="block text-sm font-medium text-white mb-2">Nom</label>
              <input 
                v-model="form.lastName"
                type="text"
                required
                class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
                placeholder="Ex: DUPONT"
              >
            </div>
          </div>

          <!-- Event Date -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">Date de l'événement</label>
            <input 
              v-model="form.date"
              type="date"
              required
              :min="minDate"
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <!-- Time (optional) -->
          <div>
            <label class="block text-sm font-medium text-white mb-2">
              Invitation à 
              <span class="text-white/60">(optionnel)</span>
            </label>
            <input 
              v-model="form.time"
              type="time"
              class="w-full rounded-lg bg-white/20 border-transparent text-white focus:border-yellow-300 focus:ring focus:ring-yellow-300/50"
            >
          </div>

          <!-- Error Message -->
          <div v-if="error" class="rounded-lg bg-red-400/20 backdrop-blur p-4 border border-red-500/50">
            <p class="text-sm text-white">{{ error }}</p>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end space-x-4 pt-6">
            <button
              type="button"
              @click="router.push('/events')"
              class="px-6 py-3 bg-white/20 text-white rounded-lg font-semibold hover:bg-white/30 
                     transition-all duration-200"
              :disabled="loading"
            >
              Annuler
            </button>
            <button
              type="submit"
              class="px-6 py-3 bg-yellow-300 text-purple-700 rounded-lg font-semibold hover:bg-yellow-200 
                     transform hover:scale-105 transition-all duration-200 shadow-lg disabled:opacity-50"
              :disabled="loading"
            >
              {{ loading ? 'Enregistrement...' : 'Enregistrer les modifications' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useEventStore } from '../stores/events';
import { formatName } from '../utils/formatters';
import type { Event } from '../types';

const router = useRouter();
const route = useRoute();
const eventStore = useEventStore();

const loading = ref(false);
const error = ref<string | null>(null);
const event = ref<Event | null>(null);

const form = ref({
  type: '',
  firstName: '',
  lastName: '',
  date: '',
  time: ''
});

// Get today's date in YYYY-MM-DD format for the min attribute
const minDate = computed(() => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
});

const parseDateAndTime = (dateString: string): { date: string; time: string } => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    // Format date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // Format time as HH:mm
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return {
      date: formattedDate,
      time: formattedTime !== '00:00' ? formattedTime : ''
    };
  } catch (err) {
    console.error('Error parsing date:', err);
    const today = new Date();
    return {
      date: today.toISOString().split('T')[0],
      time: ''
    };
  }
};

const parseEventName = (name: string): { firstName: string; lastName: string } => {
  const parts = name.trim().split(/\s+/);
  if (parts.length >= 2) {
    const lastName = parts.pop() || '';
    const firstName = parts.join(' ');
    return {
      firstName: formatName.firstName(firstName),
      lastName: formatName.lastName(lastName)
    };
  }
  return {
    firstName: formatName.firstName(name),
    lastName: ''
  };
};

onMounted(async () => {
  const eventId = route.params.id as string;
  event.value = eventStore.events.find(e => e.id === eventId) || null;

  if (event.value) {
    const { date, time } = parseDateAndTime(event.value.date);
    const { firstName, lastName } = parseEventName(event.value.name);

    form.value = {
      type: event.value.type,
      firstName,
      lastName,
      date,
      time
    };
  } else {
    router.push('/events');
  }
});

const validateDateTime = (date: string, time: string): Date => {
  const dateTimeStr = time ? `${date}T${time}:00` : `${date}T00:00:00`;
  const dateTime = new Date(dateTimeStr);
  
  if (isNaN(dateTime.getTime())) {
    throw new Error('Date ou heure invalide');
  }

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  
  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);
  
  if (selectedDate < now) {
    throw new Error('La date ne peut pas être antérieure à aujourd\'hui');
  }
  
  return dateTime;
};

const updateEvent = async () => {
  if (loading.value || !event.value) return;
  
  loading.value = true;
  error.value = null;

  try {
    const eventDate = validateDateTime(form.value.date, form.value.time);

    const eventData: Partial<Event> & { id: string } = {
      id: event.value.id,
      type: form.value.type as Event['type'],
      name: `${formatName.firstName(form.value.firstName)} ${formatName.lastName(form.value.lastName)}`,
      date: eventDate.toISOString(),
      participants: event.value.participants || [],
      gift: event.value.gift
    };

    await eventStore.updateEvent(eventData);
    router.push('/events');
  } catch (err: any) {
    error.value = err.message || 'Une erreur est survenue lors de la mise à jour de l\'événement';
    console.error('Error updating event:', err);
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>